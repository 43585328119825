import gql from 'graphql-tag';

/* USER mutations
    - ADD_USER
    - LOGIN_USER
    - UPDATE_USER_PROFILE
    - UPDATE_USER_PASSWORD
    - REMOVE_USER
*/

export const ADD_USER = gql`
    mutation addUser($input: UserSignupInput!) {
        addUser(input: $input) {
            _id
            first_name
            last_name
            email
            admin
            account_status
            full_name
        }
    }
`;

export const LOGIN_USER = gql`
    mutation loginUser($username: String!, $password: String!) {
        loginUser(username: $username, password: $password) {
            token
            user {
                _id
                first_name
                last_name
                email
                admin
                account_status
                full_name
            }
        }
    }
`;

export const UPDATE_USER_PROFILE = gql`
    mutation updateUserProfile($input: UserProfileInput!) {
        updateUserProfile(input: $input) {
            _id
            first_name
            last_name
            email
            admin
            account_status
            full_name
        }
    }
`;

export const UPDATE_USER_PASSWORD = gql`
    mutation updateUserPassword($user_id: ID!, $old_password: String!, $new_password: String!) {
        updateUserPassword(user_id: $user_id, old_password: $old_password, new_password: $new_password) {
            _id
            first_name
            last_name
            email
            admin
            account_status
            full_name
        }
    }
`;

export const REMOVE_USER = gql`
    mutation removeUser($user_id: ID!) {
        removeUser(user_id: $user_id) {
            _id
            first_name
            last_name
            email
            admin
            account_status
            full_name
        }
    }
`;



/* CLIENT mutations
    - ADD_CLIENT
    - LOGIN_CLIENT
    - UPDATE_CLIENT_PROFILE
    - UPDATE_CLIENT_PASSWORD
    - REMOVE_CLIENT
*/

export const ADD_CLIENT = gql`
    mutation addClient($input: ClientSignupInput!) {
        addClient(input: $input) {
            _id
            company_name
            username
            status
        }
    }
`;

export const LOGIN_CLIENT = gql`
    mutation loginClient($username: String!, $password: String!) {
        loginClient(username: $username, password: $password) {
            token
            client {
                _id
                company_name
                username
                status
            }
        }
    }
`;

export const UPDATE_CLIENT_PROFILE = gql`
    mutation updateClientProfile($input: ClientProfileInput!) {
        updateClientProfile(input: $input) {
            _id
            company_name
            username
            status
        }
    }
`;

export const UPDATE_CLIENT_PASSWORD = gql`
    mutation updateClientPassword($client_id: ID!, $old_password: String!, $new_password: String!) {
        updateClientPassword(client_id: $client_id, old_password: $old_password, new_password: $new_password) {
            _id
            company_name
            username
            status
        }
    }
`;

export const REMOVE_CLIENT = gql`
    mutation removeClient($client_id: ID!) {
        removeClient(client_id: $client_id) {
            _id
            company_name
            username
            status
        }
    }
`;


/* METER mutations
    - ADD_METER
    - UPDATE_METER
    - REMOVE_METER
*/

export const ADD_METER = gql`
    mutation addMeter($input: MeterSignupInput!) {
        addMeter(input: $input) {
            _id
            serial_number
            password
            data_path
            name
            description
            status
            country_of_operation
        }
    }
`;

export const UPDATE_METER = gql`
    mutation updateMeter($input: MeterProfileInput!) {
        updateMeter(input: $input) {
            _id
            serial_number
            password
            data_path
            name
            description
            status
            country_of_operation
        }
    }
`;

export const REMOVE_METER = gql`
    mutation removeMeter($meter_id: ID!) {
        removeMeter(meter_id: $meter_id) {
            _id
            serial_number
            password
            data_path
            name
            description
            status
            country_of_operation
        }
    }
`;


/* JOB mutations
    - ADD_JOB
    - UPDATE_JOB
    - REMOVE_JOB
*/

export const ADD_JOB = gql`
    mutation addJob($input: AddJobInput!) {
        addJob(input: $input) {
            _id
            client {
                _id
                company_name
            }
            number
            title
            description
            status
            created_by {
                _id
                first_name
                last_name
                full_name
            }
            updated_by {
                _id
                first_name
                last_name
                full_name
            }
            meters {
                meter {
                    _id
                    serial_number
                    name
                    description
                }
                meter_location_title
                lng
                lat
            }
        }
    }
`;

export const UPDATE_JOB = gql`
    mutation updateJob($input: UpdateJobInput!) {
        updateJob(input: $input) {
            _id
            client {
                _id
                company_name
            }
            number
            title
            description
            status
            created_by {
                _id
                first_name
                last_name
                full_name
            }
            updated_by  {
                _id
                first_name
                last_name
                full_name
            }
            meters {
                meter {
                    _id
                    serial_number
                    name
                    description
                }
                meter_location_title
                lng
                lat
            }
        }
    }
`;

export const REMOVE_JOB = gql`
    mutation removeJob($job_id: ID!) {
        removeJob(job_id: $job_id) {
            _id
        }
    }
`;