import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import regeneratorRuntime from "regenerator-runtime";
import _find from 'lodash.find';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import styled from 'styled-components';

import { capitalizeFirstLetter } from '../utils/helpers';
import { selectProfile } from '../redux/slices/profileSlice';
import { selectClients } from '../redux/slices/clientsSlice';
import { selectMeters } from '../redux/slices/metersSlice';
import { selectJobs, removeJob, initializeJobs } from '../redux/slices/jobsSlice';
import { history } from '../App';
import AdminDashboard from './AdminDashboard';
import { REMOVE_JOB } from '../utils/mutations';
import { QUERY_GET_ALL_ACTIVE_JOBS, QUERY_GET_ALL_JOBS } from "../utils/queries";
import ConfirmModal from './ConfirmModal';
import { removeItemFromTable } from '../utils/indexedDB';


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid gray;

    thead {
      background-color: #162e7e;
      color: #ffffff;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgb(128, 124, 124);
      border-right: 1px solid rgb(128, 124, 124);
      width: 150px!important;
      overflow-wrap: break-word;  

      :last-child {
        border-right: 0;
      };
      
    }
  }
`

// For Column Hiding Checkboxes
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
);

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          width: '300px',
          borderRadius: '0.25rem',
          fontSize: '0.8rem',
          border: '0',
        }}
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      style={{
        width: '100px',
        borderRadius: '0.25rem',
        fontSize: '0.8rem',
        border: '0',
      }}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`${count} records...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      style={{
        width: '100px',
        borderRadius: '0.25rem',
        fontSize: '0.8rem',
        border: '0',
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

// Main table component
function Table({ columns, data, colSelectionDivVisible, setColSelectionDivVisible }) {
  // const filterTypes = React.useMemo(
  //   () => ({
  //     // Add a new fuzzyTextFilterFn filter type.
  //     fuzzyText: fuzzyTextFilterFn,
  //     // Or, override the default text filter to use
  //     // "startWith"
  //     text: (rows, id, filterValue) => {
  //       return rows.filter(row => {
  //         const rowValue = row.values[id]
  //         return rowValue !== undefined
  //           ? String(rowValue)
  //             .toLowerCase()
  //             .startsWith(String(filterValue).toLowerCase())
  //           : true
  //       })
  //     },
  //   }),
  //   []
  // )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10)
  const firstPageRows = rows;

  return (
    <div>
      <div>
        <button
          className="btn btn-primary mx-0 my-2"
          onClick={() => setColSelectionDivVisible(prevState => !prevState)}>
          {colSelectionDivVisible == true ? "Hide Column Selection Window" : "Expand to select desired columns"}
        </button>
        {
          // <ReactHTMLTableToExcel
          // id="test-table-xls-button"
          // className="download-table-xls-button btn btn-primary"
          // table="table-to-xls"
          // filename="tablexls"
          // sheet="tablexls"
          // buttonText="Download current view as XLS" />
        }

      </div>
      {colSelectionDivVisible &&
        <div className="border border-info rounded">
          <div className="mx-1 px-1">
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
          </div>
          <hr className="w-100" />
          <div className="clearfix">
            {allColumns.map(column => (
              <div key={column.id} className="float-left mx-1 px-1" style={{ width: "250px" }}>
                <label>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                  {column.id}
                </label>
              </div>
            ))}
          </div>
        </div>
      }
      <table {...getTableProps()} id="table-to-xls">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                  style: {
                    minWidth: column.minWidth
                  }
                })}>
                  {column.render('Header')}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      {false && <div>Showing the first 20 results of {rows.length} rows</div>}
    </div>
  )
}


const AdminJobsList = () => {
  const [removeSingleJob, { error }] = useMutation(REMOVE_JOB);
  const [ loadAllJobsInfo, { data: allJobData } ] = useLazyQuery(
      QUERY_GET_ALL_JOBS,
      { fetchPolicy: "no-cache" }
  );

  const user = useSelector(selectProfile)[0];
  const jobs = useSelector(selectJobs);
  const clients = useSelector(selectClients);
  const meters = useSelector(selectMeters);
  const dispatch = useDispatch();

  const [showAllJobsSwitchDisabled, setShowAllJobsSwitchDisabled] = useState(false);
  const [showAllJobsSwitchChecked, setShowAllJobsSwitchChecked] = useState(false);

  useEffect(()=>{
      if (allJobData){
          dispatch(initializeJobs(allJobData.getAllJobs));
          setShowAllJobsSwitchDisabled(true);
      }
  }, [allJobData]);

  const [actionState, setActionState] = useState({
    object_id: undefined,
    action_type: undefined
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fetchMessage, setFetchMessage] = useState({
    msg: '',
    msg_class: 'text-danger'
  });
  let { path, url } = useRouteMatch(); // to dynamically assign logo source path

  const onClickUpdateButton = (e) => {
    setActionState({
      object_id: $(e.target).prop("id").split('_')[1],
      action_type: 'update'
    })
    setIsModalVisible(true);
  }

  const onClickAddButton = (e) => {
    history.push(`${path}/add`);
  }

  const onClickDeleteButton = (e) => {
    setActionState({
      object_id: $(e.target).prop("id").split('_')[1],
      action_type: 'delete'
    })
    setIsModalVisible(true);
  }

  const onRequestClose = () => {
    setActionState({
      object_id: undefined,
      action_type: undefined
    })
    setIsModalVisible(false);
  }

  const errorHandler = (message) => {
    setFetchMessage({
      msg: message,
      msg_class: 'text-danger'
    });
    setTimeout(() => {
      setFetchMessage({
        msg: '',
        msg_class: 'text-danger'
      });
      setIsModalVisible(false);
    }, 3000);
  }
  const successHandler = (message) => {
    setFetchMessage({
      msg: message,
      msg_class: 'text-success'
    });
    setTimeout(() => {
      setFetchMessage({
        msg: '',
        msg_class: 'text-success'
      });
      setIsModalVisible(false);
    }, 2000);
  }

  const onclickYes = async () => {
    if (actionState.action_type === 'update') {
      history.push(`${path}/update`, { job_id: actionState.object_id });
    } else if (actionState.action_type === 'delete') {
      try {
        const { data: { removeJob: removedJob } } = await removeSingleJob({ variables: { job_id: actionState.object_id } });
        await removeItemFromTable('jobs', removedJob._id); // remove data from indexedDb
        dispatch(removeJob(removedJob));
        successHandler('Job has been deleted successfully!')
      } catch (error) {
        console.log(error);
        errorHandler(error.message);
      }
    }
  }

  const onChangeShowAllJobsSwitch = async (e) => {
    setShowAllJobsSwitchChecked(!!!$(e.target).prop("checked"));
    
    loadAllJobsInfo();
  }
  // const getClientNameFromClientID = (cID) => {
  //     const clientInfo = _find(clients, client => client.client_id === cID );
  //     return clientInfo.client_name;
  // }

  const getPropertyFromObjArrary = (objArr, idPropName, idValue, propertyName) => {
    const tempObj = _find(objArr, item => item[idPropName] === idValue);
    return tempObj[propertyName];
  }

  const generateJobList = () => (
    jobs.map((job, ind) => (
      <div className="card mb-3 box-shadow meter-card" key={ind}>
        <div className="card-header d-flex justify-content-between">
          <h4 className="my-0 font-weight-normal d-inline-block">{`${job.title}`}</h4>
          {
            (user?.admin || (job.created_by._id === user._id)) &&
            <div className="btn-toolbar mb-2 mb-md-0 d-inline-block">
              <div className="btn-group mr-2 ">
                <button
                  id={`update_${job._id}`}
                  className="btn btn-sm btn-outline-secondary"
                  onClick={onClickUpdateButton}
                >Update
                </button>
                <button
                  id={`delete_${job._id}`}
                  className="btn btn-sm btn-outline-secondary"
                  onClick={onClickDeleteButton}
                >Delete
                </button>
              </div>
            </div>
          }

        </div>
        <div className="card-body">
          <p className="text-muted"><strong>Job Number: </strong>{job.number}</p>
          <p className="text-muted"><strong>Client: </strong>{getPropertyFromObjArrary(clients, '_id', job.client._id, 'company_name')}</p>
          <p className="text-muted"><strong>Job Description (Notes): </strong>{job.description}</p>
          <p className="text-muted"><strong>Job Status: </strong>{job.status}</p>
          <p className="text-muted"><strong>Created By: </strong>{`${job.created_by.first_name} ${job.created_by.last_name}`}</p>
          {
            (job.updated_by !== null) &&
            <p className="text-muted"><strong>Last Updated By: </strong>{`${job.updated_by.first_name} ${job.updated_by.last_name}`}</p>
          }
          <p className="text-muted"><strong>Deployed Meter List: </strong></p>
          <ul className="list-unstyled mt-3 mb-4 text-muted">
            {job.meters.map((item, index) => (
              <li key={index}>
                <strong>Location {index + 1}</strong>:
                {` ${item.meter_location_title} (${item.lat}, ${item.lng}) => Meter (Netbox): ${getPropertyFromObjArrary(meters, '_id', item.meter._id, 'serial_number')}`}

              </li>
            ))}
          </ul>
        </div>
      </div>
    ))
  );

  const [colSelectionDivVisible, setColSelectionDivVisible] = useState(false);
  let getWindowHeight = () => {
    return ($(window).height() - 100).toString() + "px";
  }

  const columnObj = {
    number: "Job Number",
    title: "Title",
    description: "Description",
    status: "Status",
    client: "Client",
    created_by: "Created By",
    updated_by: "Updated By",
    meters: "Meters",
    _id: "Action"
  }

  let generateJobTableColumns = () => {
    let keys = Object.keys(columnObj)
    // console.log("keys:", keys);
    let columns = keys.map((e) => {
      let tempColObj = {}
      switch (e) {
        case "status":
          tempColObj = {
            Header: columnObj[e],
            accessor: e,
            Filter: SelectColumnFilter,
            filter: 'includes',
            minWidth: 80
          };
          break;
        case "client":
          tempColObj = {
            Header: columnObj[e],
            accessor: 'client.company_name',
            Filter: SelectColumnFilter,
            filter: 'includes',
            minWidth: 180
          };
          break;
        case "created_by":
        case "updated_by":
          tempColObj = {
            Header: columnObj[e],
            accessor: `${e}.full_name`,
            filter: 'includes',
            minWidth: 180
          };
          break;
        case "meters":
          tempColObj = {
            Header: columnObj[e],
            id: e,
            accessor: data => {
              let output = [];
              data.meters.map((meterItem, ind) => {
                output.push(
                  <div>
                    <strong><u>{`Setup ${ind + 1}:`}</u></strong>
                    <div>{`Location: ${meterItem.meter_location_title}`}</div>
                    <div>{`Coordinates: ${meterItem.lat}, ${meterItem.lng}`}</div>
                    <div>{`Meter: ${meterItem.meter.name} (${meterItem.meter.serial_number})`}</div>
                  </div> 
              )});
              return output;
            },
            filter: 'includes',
            minWidth: 350
          };
          break;
          case "_id":
            tempColObj = {
              Header: columnObj[e],
              id: e,
              accessor: data => (
                  <div>
                  {
                  (user?.admin || (data.created_by._id === user?._id)) &&
                  <div className="btn-toolbar mb-2 mb-md-0 d-inline-block">
                    <div className="btn-group mr-2 ">
                      <button
                        id={`update_${data._id}`}
                        className="btn btn-sm btn-outline-secondary"
                        onClick={onClickUpdateButton}
                      >Update
                      </button>
                      <button
                        id={`delete_${data._id}`}
                        className="btn btn-sm btn-outline-secondary"
                        onClick={onClickDeleteButton}
                      >Delete
                      </button>
                    </div>
                  </div>
                  }
                  </div>
              ),
              
              minWidth: 150
            };
            break;
        case 'title':
          tempColObj = {
            Header: columnObj[e],
            accessor: e,
            minWidth: 250
          };
          break;
        default:
          tempColObj = {
            Header: columnObj[e],
            accessor: e,
            minWidth: 100
          };
      }
      return tempColObj;
    });
    // console.log("columns", columns);
    return columns;
  }

  const columns = React.useMemo(generateJobTableColumns, []);
  const data = React.useMemo(() => { return jobs }, [jobs]);

  return (
    <AdminDashboard>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Jobs</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="form-check form-switch mx-4 pt-2">
            <input 
              className="form-check-input" 
              type="checkbox" 
              id="switchShowAllJobs" 
              disabled={showAllJobsSwitchDisabled}
              onChange={onChangeShowAllJobsSwitch}
            />
            <label className="form-check-label" htmlFor="switchShowAllJobs"><strong>Show All Jobs</strong></label>
          </div>
          <button
            className="btn btn-primary"
            onClick={onClickAddButton}
          >
            Add Job
          </button>
        </div>
      </div>
      <div className="w-100">
        {
          // generateJobList()
          // style={{ "height": getWindowHeight(), "overflow": "scroll" }}
          <div className="form-boundary mt-10 p-2" style={{ "overflow": "scroll" }}>
            <Styles>
              <Table
                columns={columns}
                data={data}
                colSelectionDivVisible={colSelectionDivVisible}
                setColSelectionDivVisible={setColSelectionDivVisible} />
            </Styles>
          </div>
        }
      </div>

      <ConfirmModal 
            isModalVisible={isModalVisible}
            onRequestClose={onRequestClose}
            actionState={actionState}
            onclickYes={onclickYes}
            fetchMessage={fetchMessage}
        />
    </AdminDashboard>
  )
}

export default AdminJobsList;