import gql from 'graphql-tag';

export const QUERY_CLIENT_ME = gql`
    query {
        clientMe {
            _id
            company_name
            username
            status
        }
    }
`;

export const QUERY_USER_ME = gql`
    query {
        userMe {
            _id
            first_name
            last_name
            email
            admin
            account_status
            full_name
        }
    }
`;

export const QUERY_GET_ALL_USERS = gql`
    query {
        getAllUsers {
            _id
            first_name
            last_name
            email
            admin
            account_status
            full_name
        }
    }
`;

export const QUERY_GET_METERS = gql`
    query {
        getMeters {
            _id
            serial_number
            password
            data_path
            name
            description
            status
            country_of_operation
        }
    }
`;

export const QUERY_GET_JOBS_BY_CLIENT_ID = gql`
    query getJobsByClientId($client_id: ID!){
        getJobsByClientId(client_id: $client_id) {
            _id
            client {
                _id
                company_name
            }
            number
            title
            description
            status
            created_by {
                _id
                first_name
                last_name
                full_name
            }
            updated_by {
                _id
                first_name
                last_name
                full_name
            }
            meters {
                meter {
                    _id
                    serial_number
                    name
                    description
                }
                meter_location_title
                lng
                lat
            }
        }
    }
`;

export const QUERY_GET_ALL_ACTIVE_JOBS = gql`
    query {
        getAllActiveJobs {
            _id
            client {
                _id
                company_name
            }
            number
            title
            description
            status
            created_by {
                _id
                first_name
                last_name
                full_name
            }
            updated_by {
                _id
                first_name
                last_name
                full_name
            }
            meters {
                meter {
                    _id
                    serial_number
                    name
                    description
                }
                meter_location_title
                lng
                lat
            }
        }
    }
`;

export const QUERY_GET_ALL_JOBS = gql`
    query {
        getAllJobs {
            _id
            client {
                _id
                company_name
            }
            number
            title
            description
            status
            created_by {
                _id
                first_name
                last_name
                full_name
            }
            updated_by {
                _id
                first_name
                last_name
                full_name
            }
            meters {
                meter {
                    _id
                    serial_number
                    name
                    description
                }
                meter_location_title
                lng
                lat
            }
        }
    }
`;

export const QUERY_GET_ALL_CLIENTS = gql`
    query {
        getAllClients {
            _id
            company_name
            username
            status
        }
    }
`;

export const QUERY_GET_DATA = gql`
    query getData($input: NetBoxDataInput!) {
        getData(input: $input) {
            location_name
            netbox_serial_number
            data_x
            data_y
            lat
            lng
            flag
            last_updated_at
        }
    }
`;