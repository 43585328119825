import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import Plot from 'react-plotly.js';
import _mean from 'lodash.mean';
import _find from 'lodash.find';
import { useLazyQuery } from '@apollo/react-hooks';
// import { Line } from 'react-chartjs-2';
// import 'chartjs-plugin-zoom';

import { selectProfile } from '../redux/slices/profileSlice';
import { selectClients } from '../redux/slices/clientsSlice';
import { selectMeters } from '../redux/slices/metersSlice';
import { selectJobs } from '../redux/slices/jobsSlice';
import { initializeGraphs, selectGraphs } from '../redux/slices/graphsSlice';
import { QUERY_GET_DATA } from "../utils/queries";
import GoogleMap from './GoogleMap';
import Navbar from './Navbar';
import { history } from '../App';

// import obj from '../state.json';

// let graphObj = JSON.parse(obj.payload)[3].payload;

// console.log("graphObj0", JSON.parse(graphObj[0].data_x[2]));
// console.log("graphObj0", (graphObj[0].data_y[2]));
const TIME_INTERVAL = 300000;
const staticLayout = {
    title: 'Continuous Sound Level',
    showlegened: 'false',
    height: 400,
    yaxis: {
        title: 'Sound Level (dBA)',
        range: [20, 90],
        mirror: true,
        showline: true
    },
    xaxis: {
        title: 'Time',
        mirror: true,
        showline: true,
        nticks: 10,
        rangeslider: {
            thickness: 0.15,
            bgcolor: "rgba(0, 0, 0, 0.1)"
        },
        // tickformat: '%d %B (%a) <br /> %Y'
        tickformat: '%b %d (%a, %H:%M) <br /> %Y'
    }
};


const Graph = () => {
    // const client = useSelector(selectClient)[0];
    const [ loadGraphData, { data: graphData } ] = useLazyQuery(
        QUERY_GET_DATA,
        { fetchPolicy: "no-cache" }
    );
    const profile = useSelector(selectProfile)[0];
    const meters = useSelector(selectMeters);
    const jobs = useSelector(selectJobs);
    const graphs = useSelector(selectGraphs);
    let location = useLocation();
    const dispatch = useDispatch();

    const [center, setCenter] = useState({ lat: 0.0, lng: 0.0 });
    const [markers, setMarkers] = useState([]);
    let interval;

    useEffect(() => {
        if(profile){
            if ((graphs.length == 0) && (profile?.admin === undefined)) {
                history.push(`/clients/${profile?._id}/dashboard`);
            } else if ((graphs.length == 0) && (profile?.admin !== undefined)) {
                history.push('/paae/dashboard');
            }
        }
    }, [graphs, profile]);

    useEffect(() => {
        if (jobs.length !== 0){
            if (!!location.state) {
                if (location.state.job_id) {
                    let tempJobItem = _find(jobs, job => job?._id === location.state.job_id);
                    setCenter({
                        lat: _mean(tempJobItem.meters.map(item => parseFloat(item.lat))),
                        lng: _mean(tempJobItem.meters.map(item => parseFloat(item.lng)))
                    });
                    setMarkers(tempJobItem.meters.map((item, ind) => ({
                        location: {
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng)
                        },
                        title: item.meter_location_title
                    })));
                    // console.log(location.state.job_id, tempJobItem, _mean(tempJobItem.location_lats), _mean(tempJobItem.location_lngs))
                }
            }
        }
    }, [location.state, jobs]);
    
    useEffect(()=>{
        if (graphData){
            console.log("graphData", graphData);
            dispatch(initializeGraphs(graphData.getData));
        }
    }, [graphData]);

    useEffect(() => {
        interval = setInterval(()=>{
            if (location.state.job_id) {
                try {
                    let tempJobItem = _find(jobs, job => job._id === location.state.job_id);
                    let netBoxInfo = [];
                    tempJobItem.meters.map((item, ind) => {
                        const temp = _find(meters, meter => meter._id === item.meter._id);
                        if (temp) {
                            netBoxInfo.push({
                                netBoxSerialNumber: temp.serial_number,
                                netBoxPassword: temp.password,
                                netBoxDataFile: temp.data_path,
                                locationName: item.meter_location_title,
                                lat: item.lat,
                                lng: item.lng
                            });
                        }
                    });
                    loadGraphData({ variables: { input: { netBoxInfo } } });
                } catch (error) {
                    console.log(error);
                }
            }
        }, TIME_INTERVAL);

        return ()=> clearInterval(interval);
    }, []);

    const generatePlots = () => {
        // console.log("graphs", graphs.length);
        let jsx = graphs.map((item, ind) => {
            let datax = item.data_x;
            let data_x = datax.map(item => JSON.parse(item));

            return (
                <div key={ind}>
                    <div className="graph-main-container mb-4 pb-1">
                        <div className="row">
                            <div className="col d-flex flex-row justify-content-start sdcol-sm-2">
                                <div className="p-2"><strong>  NTi Meter Location:</strong> {item.location_name}</div>
                                <div className="p-2"><strong className="font-weight-bold"> NTi Meter Serial No:</strong> {item.netbox_serial_number} </div>
                                <div className="p-2"><strong className="font-weight-bold"> Last Updated At:</strong> {item.last_updated_at} </div>
                            </div>
                        </div>
                        <div className="row justify-content-center m-0 p-1" id={`plot_${ind}`}>
                            {
                                (item.flag == 1) ? (
                                    <Plot
                                        className="graph-container"
                                        data={[
                                            {
                                                x: data_x,
                                                y: item.data_y,
                                                type: 'scatter',
                                                mode: 'lines',
                                                marker: {},
                                            }
                                            // {type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
                                        ]}
                                        layout={staticLayout}
                                        // layout={{...staticLayout, width: dimensions.width, height: dimensions.height}}
                                        useResizeHandler={true}
                                        useRangeSilder={true}
                                        style={{ width: "100%", height: "620px" }}
                                    // divId={`graphContainer_${ind}`}
                                    // layout={ {width: 320, height: 240, title: 'A Fancy Plot'} }
                                    />
                                ) : (
                                    <div> Currently no data is available </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        });

        return jsx;
    }

    if(graphs.length == 0){
        return (<div></div>);
    }

    return (
        <div>
            <Navbar />
            <div className="container-fluid">
                <div id="googleMap" className="map-container mt-2">
                    {
                        <GoogleMap
                            zoom={13}
                            mapCenter={center}
                            markers={markers}
                        // handleMarkerOnClick={sd} 
                        />
                    }

                </div>
                <br />
                {
                    generatePlots()
                }
            </div>
        </div>
    );
}

export default Graph;