import React from 'react';
import ReactModal from 'react-modal';
import { capitalizeFirstLetter } from '../utils/helpers';

ReactModal.setAppElement("#root");
ReactModal.defaultStyles.overlay.zIndex = '5000';
ReactModal.defaultStyles.content.height = 'auto';
ReactModal.defaultStyles.content.width = '400px';
ReactModal.defaultStyles.content.top = '50%';
ReactModal.defaultStyles.content.left = '50%';
ReactModal.defaultStyles.content.right = '0px';
ReactModal.defaultStyles.content.bottom = '0px';
ReactModal.defaultStyles.content.background = 'none';
ReactModal.defaultStyles.content.border = 'none';
ReactModal.defaultStyles.content.transform = 'translate(-50%, -50%)';

const ConfirmModal = ({ isModalVisible, onRequestClose, actionState, onclickYes, fetchMessage }) => {
    return ( 
        <ReactModal
            className=""
            isOpen={isModalVisible}
            onRequestClose={onRequestClose}
            >
            <div className="d-flex h-100">
                <div className="card card-body align-self-center text-center">
                    <h6>{actionState.action_type && capitalizeFirstLetter(actionState.action_type)} this item? </h6>
                    <button 
                        className="btn btn-sm btn-primary btn-block my-2"
                        onClick={onclickYes}>
                        Yes
                    </button>
                    <button 
                        className="btn btn-sm btn-primary btn-block"
                        onClick={onRequestClose}>
                        No
                    </button>
                    <div className={`d-flex justify-content-center p-0 ${fetchMessage.msg_class}`} >
                        <small>{fetchMessage.msg}</small>
                    </div>
                </div>
            </div> 
        </ReactModal>
     );
}
 
export default ConfirmModal;