import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Page404 = () => {
    let location = useLocation();
  return (
      <div className="h-100">
        <main className="main h-100" role="main">
            <div className="bg-light h-100">
            <div className="container h-100 d-flex justify-content-center align-items-center">
                    <div className="row">
                        <div className="scol-sm-10 scol-md-8 scol-lg-6 mx-auto">
                            <div className="text-center ">
                                <h1 className="display-1 font-weight-bold">404</h1>
                                <p className="h1">Page not found</p>
                                <p className="h5 font-weight-normal mt-3 mb-4">The page you are looking for might have been removed</p>
                                <Link to="/" className="btn btn-primary btn-lg">Return to home</Link>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
  </div>

  )
}

export default Page404;