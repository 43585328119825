import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Auth from '../utils/auth';
import { selectProfile } from '../redux/slices/profileSlice';

const PrivateRoute = ({ component: Component, userType, ...rest }) => {
    const client = useSelector(selectProfile)[0];

    if(!Auth.loggedIn()) {
        return (
            <Route {...rest}
              component={( props ) =>
                  <Redirect to="/" />
              }
            />
        );
    }
    
    if (Auth.loggedIn() && userType === "user_admin") {
        return (
            <Route
                {...rest}
                render={({children}) =>
        
                (Auth.getProfileType() === "admin" || Auth.getProfileType() === "user") ? (
                    <Component {...children} />
                ) : (
                    <Fragment>
                    {Auth.getProfileType() === "client" && <Redirect to={{pathname:`/clients/${client._id}/dashboard`}} />}
                    {Auth.getProfileType() === "guest" && <Redirect to="/"/>}
                    </Fragment>
                )
                }
            />
        );
    }
    
    if (Auth.loggedIn() && userType === "client") {
        return (
            <Route
                {...rest}
                render={({children}) =>
        
                (Auth.getProfileType() === "client") ? (
                    <Component {...children} />
                ) : (
                    <Fragment>
                    {(Auth.getProfileType() === "admin" || Auth.getProfileType() === "user") && <Redirect to="/paae/dashboard" />}
                    {Auth.getProfileType() === "guest" && <Redirect to="/"/>}
                    </Fragment>
                )
                }
            />
        );
    }

  }

  export default PrivateRoute;

//   const PrivateRoute = ({ component: Component, ...rest }) => {
//     const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
//       return (
//         <Route {...rest}
//           component={( props ) =>
//           isAuthenticated ? (
//               <Component />
//             ) : (
//               <Redirect to="/" />
//             )
//           }
//         />
//       );
//     }