import { createSlice, current } from '@reduxjs/toolkit';

export const graphsSlice = createSlice({
  name: 'graphs',
  initialState: [],
  reducers: {
    resetGraphs: (state, action) =>{
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
    }, 
    initializeGraphs: (state, action) => {
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
      action.payload.map(item => state.push(item)); // payload: []
    }
  }
});

export const { resetGraphs, initializeGraphs } = graphsSlice.actions;

export const selectGraphs = state => state.graphs;

export default graphsSlice.reducer;