import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import regeneratorRuntime from "regenerator-runtime";
import { withRouter, Redirect } from'react-router-dom';

import Signin from './Signin';
import { initializeProfile, resetProfile } from '../redux/slices/profileSlice';
import { resetUsers } from '../redux/slices/usersSlice';
import { resetClients } from '../redux/slices/clientsSlice';
import { resetMeters, initializeMeters } from '../redux/slices/metersSlice';
import { resetJobs, initializeJobs } from '../redux/slices/jobsSlice';
import { resetGraphs } from '../redux/slices/graphsSlice';
import { history } from '../App';
import { LOGIN_CLIENT } from "../utils/mutations";
import { QUERY_CLIENT_ME, QUERY_GET_METERS, QUERY_GET_JOBS_BY_CLIENT_ID } from "../utils/queries";
import Auth from '../utils/auth';
import { initializeTable } from '../utils/indexedDB';

const ClientSignin = () => {
    const [loginClient, { error }] = useMutation(LOGIN_CLIENT);
    const [ loadClientInfo, { data: clientInfoData } ] = useLazyQuery(
        QUERY_CLIENT_ME,
        { fetchPolicy: "no-cache" }
    );
    const [ loadMetersInfo, { data: metersInfoData } ] = useLazyQuery(
        QUERY_GET_METERS,
        { fetchPolicy: "no-cache" }
    );
    const [ loadJobsByClientId, { data: jobsByClientIdData } ] = useLazyQuery(
        QUERY_GET_JOBS_BY_CLIENT_ID,
        { fetchPolicy: "no-cache" }
    );
    
    const dispatch = useDispatch();
    const [ msg, setMsg ] = useState(""); 

    const updateMsg = (newMsg) => setMsg(newMsg);

    useEffect(async ()=>{
        if (clientInfoData){
            await initializeTable('profile', [clientInfoData.clientMe]);
            dispatch(initializeProfile(clientInfoData.clientMe));
            loadJobsByClientId({ variables: { client_id: clientInfoData.clientMe._id } });
        }
    }, [clientInfoData]);

    useEffect(async ()=>{
        if (metersInfoData){
            await initializeTable('meters', metersInfoData.getMeters);
            dispatch(initializeMeters(metersInfoData.getMeters));
        }
    }, [metersInfoData]);

    useEffect(async ()=>{
        if (jobsByClientIdData) {
            await initializeTable('jobs', jobsByClientIdData.getJobsByClientId);
            dispatch(initializeJobs(jobsByClientIdData.getJobsByClientId));
        }
    }, [jobsByClientIdData]);

    useEffect(()=>{
        if (clientInfoData && metersInfoData && jobsByClientIdData) {
            history.replace(`/clients/${clientInfoData.clientMe._id}/dashboard`);
        }
    }, [clientInfoData, metersInfoData, jobsByClientIdData]);

    const onClickSigninButton = async (username, password) => {
        try {
            const {data:{loginClient:{token}}} = await loginClient({ variables: { username, password } });
            Auth.login(token);

            // Reset all states
            dispatch(resetProfile());
            dispatch(resetUsers());
            dispatch(resetClients());
            dispatch(resetMeters());
            dispatch(resetJobs());
            dispatch(resetGraphs());

            // Get Info
            loadClientInfo();
            loadMetersInfo();
        } catch (e) {
            updateMsg(e.message);
            console.log(e);
        }
    }

    return ( 
        <Signin 
            signinType="client"
            onClickSigninButton={onClickSigninButton}
            msg={msg}
            updateMsg={updateMsg}
        /> );
}
 
export default ClientSignin;