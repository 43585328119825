import { createSlice, current } from '@reduxjs/toolkit';

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: [],
  reducers: {
    resetClients: (state, action) =>{
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
    },    
    initializeClients: ( state, action ) => {
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
      action.payload.map(item => state.push(item)); // payload: []
    },
    addClient: (state, action) => {
      state.push(action.payload);  // payload: {}
    },
    updateClient: (state, action) => {
        const index = state.findIndex((client)=>client._id == action.payload._id);
        state[index] = action.payload;  // payload: {}
    },
    removeClient: (state, action) => {
      const index = state.findIndex((client)=>client._id == action.payload._id);
      state.splice(index, 1); // payload: {}
    }
  }
});

export const { resetClients, initializeClients, addClient, updateClient, removeClient } = clientsSlice.actions;

export const selectClients = state => state.clients;

export default clientsSlice.reducer;