import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Switch, Route, Router, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import $ from 'jquery';

import Navbar from './Navbar';
import { history } from '../App';


const AdminDashboard = ({ children, ...rest }) => {
    // const client = useSelector(selectClient)[0];
    // const jobs = useSelector(selectJobs);
    let { path, url } = useRouteMatch(); // to dynamically assign logo source path
    let location = useLocation();

    useEffect(() => {
        let [pathSlug] = location.pathname.split("/").slice(-1);
        if (pathSlug === "add" || pathSlug === "update") {
            [pathSlug] = location.pathname.split("/").slice(-2);
        }

        console.log("path and loc in admin route", path, pathSlug);
        switch (pathSlug) {
            case "":
                $("a#dashboard").addClass("active");
                break;
            default:
                $(`a#${pathSlug}`).addClass("active");
        }
        $("a").each(function (index) {
            if ($(this).prop("id") != pathSlug)
                $(this).removeClass("active");
        });
    }, [location.pathname]);
    

    return (
                <div>
                    {
                        <div className="sticky-top flex-md-nowrap p-0">
                            <Navbar />
                        </div>
                    }
                    <div className="container-fluid" style={{height: "100vh"}}>
                        <div className="row" style={{height: "100vh"}}>

                            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                                <div className="sidebar-sticky">
                                    <ul className="nav flex-column">
                                        <li className="nav-item">
                                            <Link id="dashboard" className="nav-link" to="/paae/dashboard">
                                                Dashboard
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link id="clients" className="nav-link" to="/paae/dashboard/clients">
                                                Clients
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link id="jobs" className="nav-link" to="/paae/dashboard/jobs">
                                                Jobs
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link id="meters" className="nav-link" to="/paae/dashboard/meters">
                                                Meters
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link id="profile" className="nav-link" to="/paae/dashboard/profile">
                                                Profile
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>

                            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                                {
                                    children
                                }
                            </main>
                        </div>
                    </div>
                    </div>
    );     
}

export default AdminDashboard;