import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement("#root");
ReactModal.defaultStyles.overlay.zIndex = '5000';
ReactModal.defaultStyles.content.height = 'auto';
ReactModal.defaultStyles.content.width = '400px';
ReactModal.defaultStyles.content.top = '50%';
ReactModal.defaultStyles.content.left = '50%';
ReactModal.defaultStyles.content.right = '0px';
ReactModal.defaultStyles.content.bottom = '0px';
ReactModal.defaultStyles.content.background = 'none';
ReactModal.defaultStyles.content.border = 'none';
ReactModal.defaultStyles.content.transform = 'translate(-50%, -50%)';

const ReactModalWait = (props) => {
    return (
        <ReactModal
            className="modal-booking-item"
            id="modal-booking-item-window"
            isOpen={props.isModalVisible}
        // onRequestClose={()=>setIsModalVisible(false)}
        >
            <div style={{ height: "100vh" }}>
                <div className="wait h-100">
                    <div className="row align-items-center h-100">
                        <div className="col text-center">
                            <h1 className="h4"> Data is being fetched (This may take several minutes). Please wait....</h1>
                            <br />
                            <div className="justify-content-center">
                                <div className="loader"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ReactModal>
    );
}

export default ReactModalWait;
