import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import regeneratorRuntime from "regenerator-runtime";

import AdminDashboard from './AdminDashboard';
import { selectProfile, initializeProfile } from '../redux/slices/profileSlice';
import { selectClients, initializeClients, removeClient } from '../redux/slices/clientsSlice';
import { history } from '../App';
import { REMOVE_CLIENT } from '../utils/mutations';
import ConfirmModal from './ConfirmModal';
import { removeItemFromTable } from '../utils/indexedDB';

const AdminClientsList = () => {
    const [removeSingleClient, { error }] = useMutation(REMOVE_CLIENT);

    const user = useSelector(selectProfile)[0];
    const clients = useSelector(selectClients);
    console.log("user profile here, ", user);
    const dispatch = useDispatch();
    const [actionState, setActionState] = useState({
        object_id: undefined,
        action_type: undefined
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fetchMessage, setFetchMessage] = useState({
        msg: '',
        msg_class: 'text-danger'
    });
    let { path, url } = useRouteMatch(); // to dynamically assign logo source path

    // useEffect(async ()=>{
    //     if ( clients.length == 0 ){
    //         const profileFromIdB = await getAllFromTable('profile');
    //         const clientsFromIdB = await getAllFromTable('clients');
    //         dispatch(initializeProfile(profileFromIdB[0]));
    //         dispatch(initializeClients(clientsFromIdB));
    //     }
    // }, [user, clients]);

    const onClickUpdateButton = (e) => {
        // console.log($(e.target).prop("id").split('_')[1]);
        setActionState({
            object_id: $(e.target).prop("id").split('_')[1],
            action_type: 'update'
        })
        setIsModalVisible(true);
    }

    const onClickAddButton = (e) => {
        history.push(`${path}/add`);
    }

    const onClickDeleteButton = (e) => {
        setActionState({
            object_id: $(e.target).prop("id").split('_')[1],
            action_type: 'delete'
        })
        setIsModalVisible(true);
    }

    const onRequestClose = () => {
        setActionState({
            object_id: undefined,
            action_type: undefined
        })
        setIsModalVisible(false);
    }

    const errorHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-danger'
        });
        setTimeout(() => {
            setFetchMessage({
                msg: '',
                msg_class: 'text-danger'
            });
            setIsModalVisible(false);
        }, 3000);
    }
    const successHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-success'
        });
        setTimeout(() => {
            setFetchMessage({
                msg: '',
                msg_class: 'text-success'
            });
            setIsModalVisible(false);
        }, 2000);
    }

    const onclickYes = async () => {
        if (actionState.action_type === 'update') {
            history.push(`${path}/update`, { client_id: actionState.object_id });
        } else if (actionState.action_type === 'delete') {
            try {
                const { data: { removeClient: removedClient } } = await removeSingleClient({ variables: { client_id: actionState.object_id } });
                await removeItemFromTable('clients', removedClient._id); // remove data from indexedDb
                dispatch(removeClient(removedClient));
                successHandler('Client has been deleted successfully!');
            } catch (error) {
                console.log(error);
                errorHandler(error.message);
            }
        }
    }

    const generateClientList = () => (
        clients.map((client, ind) => (
            <div className="card mb-3 box-shadow meter-card" key={ind}>
                <div className="card-header d-flex justify-content-between">
                    <h4 className="my-0 font-weight-normal d-inline-block">{`${client.company_name}`}</h4>
                    {
                        user?.admin &&
                        <div className="btn-toolbar mb-2 mb-md-0 d-inline-block">
                            <div className="btn-group mr-2 ">
                                <button
                                    id={`update_${client._id}`}
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={onClickUpdateButton}
                                >Update
                                </button>
                                <button
                                    id={`delete_${client._id}`}
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={onClickDeleteButton}
                                >Delete
                                </button>
                            </div>
                        </div>
                    }
                </div>
                <div className="card-body">
                    <p className="text-muted"><strong>Client Status: </strong>{client.status}</p>
                    <p className="text-muted"><strong>Client Username: </strong>{client.username}</p>
                    {
                        // user?.admin &&
                        // <p className="text-muted"><strong>Client Password: </strong>{client.password}</p>
                    }

                </div>
            </div>
        ))
    );

    return (
        <AdminDashboard>
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Clients</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        {
                            user?.admin &&
                            <button
                                className="btn btn-primary"
                                onClick={onClickAddButton}
                            >
                                Add Client
                            </button>
                        }
                    </div>
                </div>
                <div className="w-100">
                    {generateClientList()}
                </div>

                <ConfirmModal
                    isModalVisible={isModalVisible}
                    onRequestClose={onRequestClose}
                    actionState={actionState}
                    onclickYes={onclickYes}
                    fetchMessage={fetchMessage}
                />
            </div>
        </AdminDashboard>
    )
}

export default AdminClientsList;