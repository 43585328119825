import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import _find from 'lodash.find';
import _isMatch from 'lodash.ismatch';
import regeneratorRuntime, { values } from "regenerator-runtime";
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { selectMeters, addMeter, updateMeter } from '../redux/slices/metersSlice';
import { runMeterFormSchema } from '../utils/helpers';
import { history } from '../App';
import { ADD_METER, UPDATE_METER } from '../utils/mutations';
import AdminDashboard from './AdminDashboard';
import { addItemToTable, updateItemInTable } from '../utils/indexedDB';


const initialState = {
    meter_id: '',
    meter_serial_number: '',
    meter_password: '',
    meter_data_path: '/NetBox/XL2/Projects/.Unsaved/SLM/_123_Rpt_Report.txt',
    meter_name: '',
    meter_description: '',
    meter_status: "ACTIVE",
    meter_country_of_operation: "CANADA"
};

const MeterForm = (props) => {
    const [ addSingleMeter ] = useMutation(ADD_METER);
    const [ updateMeterProfile ] = useMutation(UPDATE_METER);

    const dispatch = useDispatch();
    const meters = useSelector(selectMeters);
    const [ formAction, setFormAction ] = useState('Add'); 
    const [ meterItem, setMeterItem ] = useState(initialState);
    const [ fetchMessage, setFetchMessage ] = useState({
        msg: '',
        msg_class: 'text-danger'
    }); 
    let location = useLocation();

    useEffect(() => {
        if (!!location.state) {
            if (location.state.meter_id) {
                let { _id, serial_number, password, data_path, name, description, status, country_of_operation } = _find(meters, meter => meter._id === location.state.meter_id );
                // setMeterItem(prevItem => ({
                //     ...prevItem,
                //     ...tempMeterItem
                // }));
                setMeterItem(prevItem => ({
                    ...prevItem,
                    meter_id: _id,
                    meter_serial_number: serial_number,
                    meter_password: password,
                    meter_data_path: data_path,
                    meter_name: name,
                    meter_description: description,
                    meter_status: status,
                    meter_country_of_operation: country_of_operation
                }));
                setFormAction('Update');
            }
        } 
    }, [location.state]);

    const errorHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-danger'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-danger'
            }); 
        }, 3000);
    }
    const successHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-success'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-success'
            });
            history.push('/paae/dashboard/meters'); 
        }, 2000);
    }
    const onClickSubmitButton = async ({ meter_id, meter_serial_number, meter_password, meter_data_path, meter_name, meter_description, meter_status, meter_country_of_operation }, setSubmittingCB) => {
        try {
            if (formAction === 'Add') {
                const {data:{ addMeter: addedMeter }} = await addSingleMeter({ 
                    variables: { 
                        input: {
                            serial_number: meter_serial_number,
                            password: meter_password,
                            data_path: meter_data_path,
                            name: meter_name,
                            description: meter_description,
                            status: meter_status,
                            country_of_operation: meter_country_of_operation
                        }
                    } 
                });

                await addItemToTable('meters', addedMeter); // add item to indexedDb
                dispatch(addMeter(addedMeter));
                successHandler('Meter has been added to the database successfully!');
            } else if (formAction === 'Update') {
                const {data:{ updateMeter: updatedMeter }} = await updateMeterProfile({ 
                    variables: { 
                        input: {
                            meter_id,
                            serial_number: meter_serial_number,
                            password: meter_password,
                            data_path: meter_data_path,
                            name: meter_name,
                            description: meter_description,
                            status: meter_status,
                            country_of_operation: meter_country_of_operation
                        }
                    } 
                });

                await updateItemInTable('meters', updatedMeter); // update item in indexedDb
                dispatch(updateMeter(updatedMeter));
                successHandler('Meter info has been updated successfully!');
            }
            setSubmittingCB(false);
        } catch (error){
            console.log(error);
            errorHandler(error.message);
            setSubmittingCB(false);
        }
    }

    const checkForNewInput = (values) => {
        let flag = false;
        if (!!location.state) {
            if (location.state.meter_id) {
                let { _id, serial_number, password, data_path, name, description, status, country_of_operation } = _find(meters, meter => meter._id === location.state.meter_id );
                flag = _isMatch(values, {
                    meter_id: _id,
                    meter_serial_number: serial_number,
                    meter_password: password,
                    meter_data_path: data_path,
                    meter_name: name,
                    meter_description: description,
                    meter_status: status,
                    meter_country_of_operation: country_of_operation
                });
            };
        }
        return flag;
    }
    return (
        <AdminDashboard>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">{`${formAction}`} Meter {location.state && `(Meter ID: ${meterItem.meter_id})`}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={meterItem}
                onSubmit={(values, actions) => {
                    onClickSubmitButton(values, actions.setSubmitting);
                }}
                validationSchema={runMeterFormSchema()}
            > 
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form>
                    <div className="card-body">
                        <div className="row pb-3">
                            <div className="col-md-4 pb-3 pb-md-1">
                                <strong>Meter Netbox Serial Number:</strong>
                                <Field
                                    type="text"
                                    name="meter_serial_number"
                                    id="meter_serial_number"
                                    className="form-control"
                                    placeholder="ABC1E-EF3GR"
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage name="meter_serial_number" />
                                </div>
                                
                            </div>
                            <div className="col-md-4  pb-3 pb-md-1">
                                <strong>Meter Netbox Password:</strong>
                                <Field
                                    type="text"
                                    name="meter_password"
                                    id="meter_password"
                                    className="form-control"
                                    placeholder=''
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage className="text-danger" name="meter_password" />
                                </div>
                            </div>

                            <div className="col-md-4  pb-3 pb-md-1">
                                <strong>Meter Name:</strong>
                                <Field
                                    type="text"
                                    name="meter_name"
                                    id="meter_name"
                                    className="form-control"
                                    placeholder='NTi kit Z'
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage className="text-danger" name="meter_name" />
                                </div>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-12"><strong>Meter Description:</strong></div>
                            <div className="col-md-12">
                                <Field
                                    type="text"
                                    name="meter_description"
                                    id="meter_description"
                                    className="form-control"
                                    placeholder='Short note about meter, e.g. with weather station etc.'
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage className="text-danger" name="meter_description" />
                                </div>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-12"><strong>Meter Data Path:</strong></div>
                            <div className="col-md-12">
                                <Field
                                    type="text"
                                    name="meter_data_path"
                                    id="meter_data_path"
                                    className="form-control"
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage className="text-danger" name="meter_data_path" />
                                </div>
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className="col-md-6  pb-3 pb-md-1">
                                <strong>Meter Status:</strong>
                                <Field
                                    name="meter_status"
                                    id="meter_status"
                                    className="form-control custom-select"
                                    as="select"
                                    onChange={(e) => {
                                        setFieldValue(e.target.name, e.target.value); 
                                    }}
                                >
                                    <option value={"ACTIVE"}>Active</option>
                                    <option value={"SUSPENDED"}>Suspended</option>
                                </Field>
                            </div>
                            <div className="col-md-6  pb-3 pb-md-1">
                                <strong>Country of Operation:</strong>
                                <Field
                                    name="meter_country_of_operation"
                                    className="form-control custom-select"
                                    id="meter_country_of_operation"
                                    as="select"
                                >
                                    <option value="CANADA">Canada</option>
                                    <option value="AUSTRALIA">Australia</option>
                                </Field>
                            </div>
                        </div>
                        <div className="form-row justify-content-center">
                            <button 
                                className="btn btn-primary btn-lg"
                                disabled={checkForNewInput(values) || isSubmitting} 
                                type="submit"
                            > 
                                {formAction} Meter 
                            </button> 
                        </div>
                        <div className={`form-row justify-content-center p-0 ${fetchMessage.msg_class}`} >
                            <small>{fetchMessage.msg}</small>
                        </div>     
                    </div>
                </Form>
            )}
            </Formik>
        </AdminDashboard>
    );
}

export default MeterForm;