import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { history } from '../App';

const Page408 = () => {
    let location = useLocation();
    let { prevLocation } = location.state || { prevLocation: { pathname: "/" } };
    let { message } = location.state || { message: "" };
    console.log("prevLocation", prevLocation, location.state);
    return (
        <div className="h-100">
            <main className="main h-100" role="main">
                <div className="bg-light h-100">
                    <div className="container h-100 d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="scol-sm-10 scol-md-8 scol-lg-6 mx-auto">
                                <div className="text-center ">
                                    <h1 className="display-1 font-weight-bold">408</h1>
                                    <p className="h1">Request Timed Out</p>
                                    <p className="h5 font-weight-normal mt-3 mb-4">The request was timed out. Please try again after a few minutes...</p>
                                    <div className="text-muted"><small>{message}</small></div>
                                    <button
                                        className="btn btn-primary btn-lg"
                                        onClick={() => history.replace(prevLocation)}
                                    >
                                        Return to Dashboard
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    )
}

export default Page408;