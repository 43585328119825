import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import _find from 'lodash.find';
import _isMatch from 'lodash.ismatch';
import regeneratorRuntime from "regenerator-runtime";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/react-hooks';

import { selectProfile } from '../redux/slices/profileSlice';
import { selectClients } from '../redux/slices/clientsSlice';
import { selectMeters } from '../redux/slices/metersSlice';
import { selectJobs, addJob, updateJob } from '../redux/slices/jobsSlice';
import { runJobFormSchema } from '../utils/helpers';
import { history } from '../App';
import { ADD_JOB, UPDATE_JOB } from '../utils/mutations';
import AdminDashboard from './AdminDashboard';
import { addItemToTable, updateItemInTable } from '../utils/indexedDB';


const initialState = {
    _id: '',
    client: '',
    number: '',
    title: '',
    description: '',
    status: "ACTIVE",
    // created_by: '',
    // updated_by: '',
    meters: [
        {
            meter: '',
            meter_location_title: '',
            lng: 0.0,
            lat: 0.0
        }
    ]
};

const JobForm = (props) => {
    const [ addSingleJob ] = useMutation(ADD_JOB);
    const [ updateSingleJob ] = useMutation(UPDATE_JOB);

    const dispatch = useDispatch();
    const user = useSelector(selectProfile)[0];
    const meters = useSelector(selectMeters);
    const jobs = useSelector(selectJobs);
    const clients = useSelector(selectClients);
    
    const [ formAction, setFormAction ] = useState('Add'); 
    const [ jobItem, setJobItem ] = useState(initialState);
    const [ fetchMessage, setFetchMessage ] = useState({
        msg: '',
        msg_class: 'text-danger'
    }); 
    let location = useLocation();

    useEffect(() => {
        if (!! location.state) {
            if (location.state.job_id) {
                let { client, ...tempJobItem } = _find(jobs, job => job._id === location.state.job_id );
                const tempMeters = tempJobItem.meters.map( (meterItem, index) => ({
                    meter: meterItem.meter._id,
                    meter_location_title: meterItem.meter_location_title,
                    lng: parseFloat(meterItem.lng),
                    lat: parseFloat(meterItem.lat)
                }));

                setJobItem(prevItem => ({
                    ...prevItem,
                    client: client._id,
                    ...tempJobItem,
                    meters: tempMeters
                }));
                setFormAction('Update');
                // setTimeout(()=>console.log("JobItem", jobItem), 3000);
            }
        } 
    }, [location.state]);

    const errorHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-danger'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-danger'
            }); 
        }, 3000);
    };

    const successHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-success'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-success'
            });
            history.push('/paae/dashboard/jobs'); 
        }, 2000);
    };

    const onClickSubmitButton = async ({ _id, number, title, description, status, client, meters }, setSubmittingCB) => {
        console.log("Here")
        try {
            if (formAction === 'Add') {
                const {data:{ addJob: addedJob }} = await addSingleJob({ 
                    variables: { 
                        input: {
                            client,
                            number,
                            title,
                            description,
                            status,
                            created_by: user._id,
                            meters
                        }
                    } 
                });

                await addItemToTable('jobs', addedJob); // add data to indexedDb
                dispatch(addJob(addedJob));
                successHandler('Job has been added to the database successfully!');
            } else if (formAction === 'Update') {
                const {data:{ updateJob: updatedJob }} = await updateSingleJob({ 
                    variables: { 
                        input: {
                            job_id: _id,
                            client,
                            number,
                            title,
                            description,
                            status,
                            updated_by: user._id,
                            meters
                        }
                    } 
                });

                await updateItemInTable('jobs', updatedJob); // update data in indexedDb
                dispatch(updateJob(updatedJob));
                successHandler('Job info has been updated successfully!');
            }
            setSubmittingCB(false);
        } catch (error){
            console.log(error);
            errorHandler(error.message);
            setSubmittingCB(false);
        }
    }

    const checkForNewInput = (values) => {
        let flag = false;
        if (!!location.state) {
            if (location.state.job_id) {
                let { client, ...tempJobItem } = _find(jobs, job => job._id === location.state.job_id );
                const tempMeters = tempJobItem.meters.map( (meterItem, index) => ({
                    meter: meterItem.meter._id,
                    meter_location_title: meterItem.meter_location_title,
                    lng: parseFloat(meterItem.lng),
                    lat: parseFloat(meterItem.lat)
                }));
                if (values.meters.length !== tempMeters.length) return false;
                // console.log("tempJobItem", tempJobItem, values);
                flag = _isMatch(values, { client: client._id, ...tempJobItem, meters: tempMeters } );
            };
        }
        return flag;
    }

    const generateClientsList = () => {
        let jsx = [];
        jsx.push(<option key={-1} value={''}> {'Select one ...'} </option>)
        clients.map((client, ind)=> {
            jsx.push(<option key={ind} value={client._id}> {client.company_name} </option>);
        });
        return jsx;
    }

    const generateMetersList = () => {
        let jsx = [];
        jsx.push(<option key={-1} value={0}> {'Select one ...'} </option>)
        meters.map((meter, ind)=> {
            jsx.push(<option key={ind} value={meter._id}> {`${meter.name} (${meter.serial_number})`} </option>);
        });
        return jsx;
    }

    // const generateMetersList = () => {
    //     return meters.map((meter, ind)=> (
    //         <option key={ind} value={meter.meter_id}> {`${meter.meter_name} (${meter.meter_serial_number})`} </option>
    //     ))
    // }

    return (
        <AdminDashboard>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">{`${formAction}`} Job {!!location.state && `(Job ID: ${jobItem._id})`}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={jobItem}
                onSubmit={(values, actions) => {
                    // console.log("values in here", values);
                    onClickSubmitButton(values, actions.setSubmitting);
                }}
                validationSchema={runJobFormSchema()}
            > 
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form>
                    <div className="card-body">
                        <div className="row pb-3">
                            <div className="col-md-6 pb-3 pb-md-1">
                                <strong>Job Number:</strong>
                                <Field
                                    type="text"
                                    name="number"
                                    id="number"
                                    className="form-control"
                                    placeholder="9999"
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage name="number" />
                                </div>
                                
                            </div>

                            <div className="col-md-6  pb-3 pb-md-1">
                                <strong>Job Name:</strong>
                                <Field
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="form-control"
                                    placeholder='Pembina Monitoring'
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage className="text-danger" name="title" />
                                </div>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-12"><strong>Job Description:</strong></div>
                            <div className="col-md-12">
                                <Field
                                    type="text"
                                    name="description"
                                    id="description"
                                    className="form-control"
                                    placeholder='Short note about job, e.g. Drilling monitoring at Dawson Creek'
                                />
                                <div className="text-danger font-weight-bold">
                                    <ErrorMessage className="text-danger" name="description" />
                                </div>
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className="col-md-6  pb-3 pb-md-1">
                                <strong>Client:</strong>
                                <Field
                                    name="client"
                                    id="client"
                                    className="form-control custom-select"
                                    as="select"
                                >
                                    {generateClientsList()}
                                </Field>
                            </div>
                            <div className="col-md-6  pb-3 pb-md-1">
                                <strong>Job Status:</strong>
                                <Field
                                    name="status"
                                    id="status"
                                    className="form-control custom-select"
                                    as="select"
                                    onChange={(e) => {
                                        setFieldValue(e.target.name, e.target.value); 
                                    }}
                                >
                                    <option value="ACTIVE">Active</option>
                                    <option value="COMPLETED">Completed</option>
                                    <option value="SUSPENDED">Suspended</option>
                                </Field>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col pb-3 pb-md-1">
                                <strong>Meter Info:</strong>
                                <FieldArray name="meters">
                                    {({ remove, push }) => (
                                    <div>
                                        {values.meters.length > 0 &&
                                        values.meters.map((meter, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-md-2 d-flex align-items-end pb-2 order-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => remove(index)}
                                                    >
                                                    Remove Meter
                                                    </button>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-3 pb-3 pb-md-1">
                                                            <strong>Meter:</strong>
                                                            <Field
                                                                name={`meters.${index}.meter`}
                                                                className="form-control custom-select"
                                                                as="select"
                                                            >
                                                                {generateMetersList()}
                                                            </Field>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 pb-3 pb-md-1">
                                                            <strong>Location Title:</strong>
                                                            <Field
                                                                name={`meters.${index}.meter_location_title`}
                                                                placeholder="Residence A"
                                                                type="text"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage
                                                                name={`meters.${index}.meter_location_title`}
                                                                component="div"
                                                                className="text-danger font-weight-bold"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 pb-3 pb-md-1">
                                                            <strong>Latitude:</strong>
                                                            <Field
                                                                name={`meters.${index}.lat`}
                                                                placeholder="56.512"
                                                                type="number"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage
                                                                name={`meters.${index}.lat`}
                                                                component="div"
                                                                className="text-danger font-weight-bold"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 pb-3 pb-md-1">
                                                            <strong>Longitude:</strong>
                                                            <Field
                                                                name={`meters.${index}.lng`}
                                                                placeholder="-112.123"
                                                                type="number"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage
                                                                name={`meters.${index}.lng`}
                                                                component="div"
                                                                className="text-danger font-weight-bold"
                                                            />
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        ))}
                                        <div className="my-2">
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() => push({ 
                                                    meter: '', 
                                                    meter_location_title: '',
                                                    lat: '',
                                                    lng: ''
                                                })}
                                                >
                                                Add Meter
                                            </button>
                                        </div>
                                    </div>
                                    )}
                                </FieldArray>
                                
                            </div>
                        </div>
                       
                        <div className="form-row justify-content-center">
                            <button 
                                className="btn btn-primary btn-lg"
                                disabled={checkForNewInput(values) || isSubmitting} 
                                type="submit"
                            > 
                                {formAction} Job
                            </button> 
                        </div>
                        <div className={`form-row justify-content-center p-0 ${fetchMessage.msg_class}`} >
                            <small>{fetchMessage.msg}</small>
                        </div>     
                    </div>
                </Form>
            )}
            </Formik>
        </AdminDashboard>
    );
}

export default JobForm;