import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import _find from 'lodash.find';
import $ from 'jquery';
import { useLazyQuery } from '@apollo/react-hooks';

import { selectProfile } from '../redux/slices/profileSlice';
import { initializeClients, selectClients } from '../redux/slices/clientsSlice';
import { initializeMeters, selectMeters } from '../redux/slices/metersSlice';
import { initializeJobs, selectJobs } from '../redux/slices/jobsSlice';
import { initializeGraphs, selectGraphs } from '../redux/slices/graphsSlice';
import ReactModalWait from './ReactModalWait';
import Navbar from './Navbar';
import { QUERY_GET_DATA } from "../utils/queries";
import { history } from '../App';


const LandingPage = () => {
    const [ loadGraphData, { data: graphData } ] = useLazyQuery(
        QUERY_GET_DATA,
        { fetchPolicy: "no-cache" }
    );

    const client = useSelector(selectProfile)[0];
    const meters = useSelector(selectMeters);
    const jobs = useSelector(selectJobs);
    const dispatch = useDispatch();
    const location = useLocation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState('');

    useEffect(()=>{
        if (graphData){
            console.log("graphData", graphData);
            setIsModalVisible(false);
            dispatch(initializeGraphs(graphData.getData));
            history.push(`/clients/${client._id}/jobs/${selectedJobId}/graph`, { job_id: selectedJobId });
        }
    }, [graphData]);

    const onClickShowGraphButton = async (e) => {
        setIsModalVisible(true);
        const job_id = $(e.target).prop("id");
        const job_info = _find(jobs, job => job._id === job_id);
        setSelectedJobId(job_id);

        let netBoxInfo = [];
        job_info.meters.map((item, ind) => {
            const temp = _find(meters, meter => meter._id === item.meter._id);
            if (temp) {
                netBoxInfo.push({
                    netBoxSerialNumber: temp.serial_number,
                    netBoxPassword: temp.password,
                    netBoxDataFile: temp.data_path,
                    locationName: item.meter_location_title,
                    lat: item.lat,
                    lng: item.lng
                });
            }
        });

        console.log("netBoxInfo", netBoxInfo);
        try {
            loadGraphData({ variables: { input: { netBoxInfo } } });
        } catch (error) {
            console.log(error);
            history.push(`/timeout`, { message: error.message || "", prevLocation: location });
        }
    }

    const generateJobList = (jobList) => (
        jobList.map((job, ind) => (
            <div className="card mb-4 box-shadow job-card" key={ind}>
                <div className="card-header">
                    <h4 className="my-0 font-weight-normal">{job.title}</h4>
                </div>
                <div className="card-body">
                    <div className="h-100 d-flex flex-column">
                        <h1 className="card-title pricing-card-title "> {job.number} </h1>
                        <p className="text-muted">{job.description}</p>
                        <ul className="list-unstyled mt-3 mb-4">
                            {job.meters.map((item, index) => (
                                <li key={index}><strong>Location {index + 1}</strong>: {`${item.meter_location_title} (${item.lat}, ${item.lng})`}</li>
                            ))}
                        </ul>
                        <div className="flex-grow-1 d-flex align-items-end">
                            <div className="w-100 text-center"> 
                                <button
                                    id={`${job._id}`}
                                    type="button"
                                    className="btn btn-lg btn-block btn-primary"
                                    onClick={onClickShowGraphButton}
                                >
                                    See Live Graph
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    );

    return (
        <div>
            <Navbar />

            <div className="pricing-header mt-3 px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <h1 className="display-4">{client.company_name}</h1>
                <p className="lead">Active Job List</p>
            </div>

            <div className="container">
                <div className="card-deck mb-3 text-center justify-content-center">
                    {generateJobList(jobs)}
                </div>
            </div>
            <ReactModalWait isModalVisible={isModalVisible} />


        </div>
    );
}

export default LandingPage;