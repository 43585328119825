import { configureStore } from '@reduxjs/toolkit';

import profileReducer from './slices/profileSlice';
import usersReducer from './slices/usersSlice';
import clientsReducer from './slices/clientsSlice';
import metersReducer from './slices/metersSlice';
import jobsReducer from './slices/jobsSlice';
import graphsReducer from './slices/graphsSlice';

export default configureStore({
  reducer: {
    profile: profileReducer,
    users: usersReducer,
    clients: clientsReducer,
    meters: metersReducer,
    jobs: jobsReducer,
    graphs: graphsReducer
  },
});
