import Dexie from 'dexie';

const db = new Dexie('paae_lm_idb');
db.version(1).stores({ 
    profile: '_id',
    users: '_id',
    clients: '_id',
    meters: '_id',
    jobs: '_id'
});

export const initializeTable = async (tableName, tableDataArr) => {
    await db[tableName].clear();
    await db[tableName].bulkAdd(tableDataArr);
}; 

export const clearTables = async () => {
    let tables = ['profile', 'users', 'clients', 'meters', 'jobs'];
    tables.map(async tableName => {
        await db[tableName].clear();
    });
}; 

export const getAllFromTable = async ( tableName ) => {
    return await db[tableName].toArray();    
};

export const addItemToTable = async ( tableName, itemData ) => {
    return await db[tableName].add(itemData);    
};

export const updateItemInTable = async ( tableName, itemData ) => {
    return await db[tableName].put(itemData);    
};

export const removeItemFromTable = async ( tableName, itemKey ) => {
    return await db[tableName].delete(itemKey);    
};

export default db;

// profile: '_id, first_name, last_name, email, admin, account_status, full_name',
//     clients: '_id, company_name, username, status',
//     meters: '_id, serial_number, password, data_path, name, description, status, country_of_operation',
//     jobs: '_id, client._id, client.company_name, number, title, description, status, created_by, updated_by, *meters',
//     users: '_id, first_name, last_name, email, admin, account_status, full_name'