import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import regeneratorRuntime from "regenerator-runtime";
import { withRouter, Redirect } from'react-router-dom';

import Signin from './Signin';
import { resetProfile, initializeProfile } from '../redux/slices/profileSlice';
import { resetUsers, initializeUsers } from '../redux/slices/usersSlice';
import { resetClients, initializeClients } from '../redux/slices/clientsSlice';
import { resetMeters, initializeMeters } from '../redux/slices/metersSlice';
import { resetJobs, initializeJobs } from '../redux/slices/jobsSlice';
import { resetGraphs } from '../redux/slices/graphsSlice';
import { history } from '../App';
import { LOGIN_USER } from "../utils/mutations";
import { QUERY_USER_ME, QUERY_GET_ALL_USERS, QUERY_GET_ALL_CLIENTS, QUERY_GET_METERS, QUERY_GET_ALL_ACTIVE_JOBS } from "../utils/queries";
import Auth from '../utils/auth';
import { initializeTable } from '../utils/indexedDB';


const AdminSignin = () => {
    const [loginUser, { error }] = useMutation(LOGIN_USER);
    const [ loadUserInfo, { data: userInfoData } ] = useLazyQuery(
        QUERY_USER_ME,
        { fetchPolicy: "no-cache" }
    );
    const [ loadAllUsersInfo, { data: allUsersInfoData } ] = useLazyQuery(
        QUERY_GET_ALL_USERS,
        { fetchPolicy: "no-cache" }
    );
    const [ loadAllClientsInfo, { data: allClientsInfoData } ] = useLazyQuery(
        QUERY_GET_ALL_CLIENTS,
        { fetchPolicy: "no-cache" }
    );
    const [ loadMetersInfo, { data: metersInfoData } ] = useLazyQuery(
        QUERY_GET_METERS,
        { fetchPolicy: "no-cache" }
    );
    const [ loadAllActiveJobsInfo, { data: allActiveJobsData } ] = useLazyQuery(
        QUERY_GET_ALL_ACTIVE_JOBS,
        { fetchPolicy: "no-cache" }
    );

    const dispatch = useDispatch();
    const [ msg, setMsg ] = useState("");

    useEffect(async ()=>{
        if (userInfoData){
            await initializeTable('profile', [userInfoData.userMe]);
            dispatch(initializeProfile(userInfoData.userMe));
        }
    }, [userInfoData]);

    useEffect(async ()=>{
        if (allUsersInfoData){
            await initializeTable('users', allUsersInfoData.getAllUsers);
            dispatch(initializeUsers(allUsersInfoData.getAllUsers));
        }
    }, [allUsersInfoData]);

    useEffect(async ()=>{
        if (allClientsInfoData){
            await initializeTable('clients', allClientsInfoData.getAllClients);
            dispatch(initializeClients(allClientsInfoData.getAllClients));
        }
    }, [allClientsInfoData]);

    useEffect(async ()=>{
        if (metersInfoData){
            await initializeTable('meters', metersInfoData.getMeters);
            dispatch(initializeMeters(metersInfoData.getMeters));
        }
    }, [metersInfoData]);

    useEffect(async ()=>{
        if (allActiveJobsData){
            await initializeTable('jobs', allActiveJobsData.getAllActiveJobs);
            dispatch(initializeJobs(allActiveJobsData.getAllActiveJobs));
        }
    }, [allActiveJobsData]);

    useEffect(()=>{
        if (userInfoData && allUsersInfoData && allClientsInfoData && metersInfoData && allActiveJobsData){
            history.replace(`/paae/dashboard`);
        }
    }, [userInfoData, allUsersInfoData, allClientsInfoData, metersInfoData, allActiveJobsData]);

    const updateMsg = (newMsg) => setMsg(newMsg);

    const onClickSigninButton = async (username, password) => {
        try {
            const {data:{loginUser:{token}}} = await loginUser({ variables: { username, password } });
            Auth.login(token);

            // Reset all states
            dispatch(resetProfile());
            dispatch(resetUsers());
            dispatch(resetClients());
            dispatch(resetMeters());
            dispatch(resetJobs());
            dispatch(resetGraphs());

            // Get All Info
            loadUserInfo();
            loadAllUsersInfo();
            loadAllClientsInfo();
            loadMetersInfo();
            loadAllActiveJobsInfo();
        } catch (e) {
            updateMsg(e.message);
            console.log(e);
        }
    }

    return ( 
        <Signin 
            signinType="admin"
            onClickSigninButton={onClickSigninButton}
            msg={msg}
            updateMsg={updateMsg}
        /> );
}
 
export default AdminSignin;