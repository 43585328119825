import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import regeneratorRuntime from "regenerator-runtime";

import { signInValidationSchema } from '../utils/helpers';


const Signin = (props) => {
    const [ username, setUsername ] = useState(""); 
    const [ password, setPassword ] = useState(""); 
    // const [ rememberMe, setRememberMe ] = useState(false);
    
    const nextLocation = {
        pathname: props.signinType == "client" ? `/sign-in-admin` : `/sign-in-client`,
        state: { }
    };

    const onClickSigninButton = (e) => {
        e.preventDefault();

        signInValidationSchema(props.signinType)
            .isValid({ username, password })
                .then(valid => { 
                    if (valid) {
                        props.updateMsg("");
                        props.onClickSigninButton(username, password);
                    } else {
                        props.updateMsg("Invalid username and/or password!");
                    }
                })  
                .catch(error => props.updateMsg(JSON.stringify(error)));
    }
    return (
        <div className="h-100" >
            <main className="main h-100" role="main">
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto my-4">
                            <div className="text-center">
                                <h1 className="h3">Live Monitoring Portal</h1>
                                <div className="lead">
                                    <span> Sign in to continue {(props.signinType =="admin") && "(Admin)" }</span>
                                </div>
                            </div>

                            <div className="card box-shadow">
                                <div className="card-body">
                                    <div className="m-sm-4">
                                        <div className="text-center mb-4">
                                            <img src="./assets/img/paae_logo_with_title.jpg" alt="PAAE Logo" className="img-fluid" width="280" height="132" />
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="username">Username</label>
                                                <input
                                                    id="username"
                                                    className="form-control form-control-lg" 
                                                    type={ props.signinType == "admin" ? "email": "text"} 
                                                    name="username" 
                                                    placeholder="Enter Username"
                                                    value={username}
                                                    onChange={(e)=>setUsername(e.target.value)}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input 
                                                    id="password"
                                                    className="form-control form-control-lg" 
                                                    type="password" 
                                                    name="password" 
                                                    placeholder="Enter Password" 
                                                    value={password}
                                                    onChange={(e)=>setPassword(e.target.value)}/>
                                                {
                                                // <small>
                                                //     <a href="#">Forgot password?</a>
                                                // </small>
                                                }
                                            </div>
                                            {
                                            // <div>
                                            //     <div className="custom-control custom-checkbox align-items-center">
                                            //         <input 
                                            //             id="remember-me"
                                            //             type="checkbox" 
                                            //             className="custom-control-input" 
                                            //             value="remember-me" 
                                            //             name="remember-me" 
                                            //             checked={rememberMe} 
                                            //             onChange={(e)=>setRememberMe(e.target.checked)}/>
                                            //         <label htmlFor="remember-me" className="custom-control-label text-small">Remember me</label>
                                            //     </div>
                                            // </div>
                                            }
                                            <div className="text-center mt-3">
                                                <button 
                                                    className="btn btn-lg btn-primary"
                                                    onClick={onClickSigninButton}>
                                                    Sign in
                                                </button>
                                                <p className="text-danger">
                                                    <small>{props.msg}</small>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                            {
                                props.signinType != undefined &&
                                <Link className="btn btn-link ml-4 mb-2 font-weight-normal" to={nextLocation}>Go to { props.signinType == "client" ? `Admin Sign in`: `Client Sign in`} Page</Link>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    )
}

export default Signin;