import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import React, { useState, createContext } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history';

import LandingPage from './components/LandingPage';
import AdminDashboardView from './components/AdminDashboardView';
import AdminClientsList from './components/AdminClientsList';
import ClientForm from './components/ClientForm';
import AdminJobsList from './components/AdminJobsList';
import JobForm from './components/JobForm';
import AdminMetersList from './components/AdminMetersList';
import MeterForm from './components/MeterForm';
import AdminProfile from './components/AdminProfile'; 
import ProfileForm from './components/ProfileForm'; 
import ClientSignin from './components/ClientSignin';
import AdminSignin from './components/AdminSignin';
import Graph from './components/Graph';
import PrivateRoute from './components/PrivateRoute';
import Page404 from './components/Page404';
import Page408 from './components/Page408';


// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import './css/App.css';
// import PrivateRoute from './components/PrivateRoute';
// import PublicRoute from './components/PublicRoute';
// import { StoreProvider } from "./utils/GlobalState";

const client = new ApolloClient({
    request: operation => {
        const token = localStorage.getItem('id_token');
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        });
    },
    uri: '/graphql'
});

export const history = createBrowserHistory();

const App = () => {
    return (
        <ApolloProvider client={client}>
      <div style={{height: "100vh"}}>
          <Router history={history}>
            <Switch>
            {  // To test individual component
              // <Route path="/" component={Graph} />
              // <Route path="/clients/:client_slug/dashboard" component={LandingPage} />
              // <Route path="/admin/:user/dashboard" component={Dashboard} />
            //   <Route path="/" component={ClientSignin} />
            }
            
  
              <Route path="/" exact={true}> <Redirect to="/sign-in-client" /> </Route>
              <Route path="/sign-in-client" component={ClientSignin} />
              <Route path="/sign-in-admin" component={AdminSignin} />

              <PrivateRoute path="/paae/dashboard/clients/update" userType="user_admin" component={ClientForm} />
              <PrivateRoute path="/paae/dashboard/clients/add" userType="user_admin" component={ClientForm} />
              <PrivateRoute path="/paae/dashboard/clients" userType="user_admin" component={AdminClientsList} />

              <PrivateRoute path="/paae/dashboard/meters/update" userType="user_admin" component={MeterForm} />
              <PrivateRoute path="/paae/dashboard/meters/add" userType="user_admin" component={MeterForm} />
              <PrivateRoute path="/paae/dashboard/meters" userType="user_admin" component={AdminMetersList} />

              <PrivateRoute path="/paae/dashboard/jobs/update" userType="user_admin" component={JobForm} />
              <PrivateRoute path="/paae/dashboard/jobs/add" userType="user_admin" component={JobForm} />
              <PrivateRoute path="/paae/dashboard/jobs" userType="user_admin" component={AdminJobsList} />

              <PrivateRoute path="/paae/dashboard/profile/update" userType="user_admin" component={ProfileForm} />
              <PrivateRoute path="/paae/dashboard/profile/add" userType="user_admin" component={ProfileForm} />
              <PrivateRoute path="/paae/dashboard/profile" userType="user_admin" component={AdminProfile} />

              <PrivateRoute path="/paae/dashboard" userType="user_admin" component={AdminDashboardView} />
              <PrivateRoute path="/paae/:job_id/graph" userType="user_admin" component={Graph} />

              <PrivateRoute path="/clients/:client_id/jobs/:job_id/graph" userType="client" component={Graph} />
              <PrivateRoute path="/clients/:client_id/dashboard" userType="client" component={LandingPage} />

              <Route path="/timeout" component={Page408} />
              <Route path="*" component={Page404} />
              {

              //   
            }
            </Switch>
        </Router>
      </div>
      </ApolloProvider>
    )
  }
  
export default App;
