import React, { useState, useContext } from 'react';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import $ from 'jquery';
import regeneratorRuntime from "regenerator-runtime";

import { capitalizeFirstLetter } from '../utils/helpers';
import { selectProfile } from '../redux/slices/profileSlice';
import { selectMeters, removeMeter } from '../redux/slices/metersSlice';
import { history } from '../App';
import AdminDashboard from './AdminDashboard';
import { REMOVE_METER } from '../utils/mutations';
import ConfirmModal from './ConfirmModal';
import { removeItemFromTable } from '../utils/indexedDB';


const AdminMetersList = () => {
    const [ removeSingleMeter, { error } ] = useMutation(REMOVE_METER);

    const user = useSelector(selectProfile)[0];
    const meters = useSelector(selectMeters);
    const dispatch = useDispatch();

    const [ actionState, setActionState ] = useState({
        object_id: undefined,
        action_type: undefined
    });
    const [ isModalVisible, setIsModalVisible ] = useState(false);
    const [fetchMessage, setFetchMessage] = useState({
        msg: '',
        msg_class: 'text-danger'
    }); 
    let { path, url } = useRouteMatch(); // to dynamically assign logo source path

    const onClickUpdateButton = (e) => {
        setActionState({
            object_id: $(e.target).prop("id").split('_')[1],
            action_type: 'update'
        })
        setIsModalVisible(true);
    }

    const onClickAddButton = (e) => {
        history.push(`${path}/add`);
    }

    const onClickDeleteButton = (e) => {
        setActionState({
            object_id: $(e.target).prop("id").split('_')[1],
            action_type: 'delete'
        })
        setIsModalVisible(true);
    }

    const onRequestClose = () => {
        setActionState({
            object_id: undefined,
            action_type: undefined
        })
        setIsModalVisible(false);   
    }

    const errorHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-danger'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-danger'
            });
            setIsModalVisible(false);
        }, 3000);
    }
    const successHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-success'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-success'
            });
            setIsModalVisible(false);
        }, 2000);
    }

    const onclickYes = async () => {
        if ( actionState.action_type === 'update' ) {
            // console.log("in here", actionState.object_id);
            history.push(`${path}/update`, { meter_id: actionState.object_id });
        } else if ( actionState.action_type === 'delete' ) {
            try{
                const {data:{removeMeter: removedMeter }} = await removeSingleMeter({ variables: { meter_id: actionState.object_id } });
                await removeItemFromTable('meters', removedMeter._id); // remove data from indexedDb
                dispatch(removeMeter(removedMeter));
                successHandler('Meter has been deleted successfully!')
            }catch (error){
                console.log(error);
                errorHandler(error.message);
            }
        }   
    }

    const generateMeterList = () => (
        meters.map((meter, ind) => (
            <div className="card mb-3 box-shadow meter-card" key={ind}>
                <div className="card-header d-flex justify-content-between">
                    <h4 className="my-0 font-weight-normal d-inline-block">{`${meter.serial_number} (${meter.name})`}</h4>
                    {
                        user?.admin &&
                        <div className="btn-toolbar mb-2 mb-md-0 d-inline-block">
                            <div className="btn-group mr-2 ">
                                <button
                                    id={`update_${meter._id}`} 
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={onClickUpdateButton}
                                >Update
                                </button>
                                <button
                                    id={`delete_${meter._id}`}  
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={onClickDeleteButton}
                                >Delete
                                </button>
                            </div>
                        </div>
                    }
                    
                </div>
                <div className="card-body">
                    <p className="text-muted"><strong>Meter Status: </strong>{meter.status}</p>
                    <p className="text-muted"><strong>Meter Notes: </strong>{meter.description}</p>
                    <p className="text-muted"><strong>Country of Operation: </strong>{meter.country_of_operation}</p>
                    <p className="text-muted"><strong>Meter Data Path: </strong>{meter.data_path}</p>
                    <p className="text-muted"><strong>Password: </strong>{meter.password}</p>
                </div>
            </div>
        ))
    );

    return (
        <AdminDashboard>
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">Meters</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    {
                        user?.admin &&
                        <button 
                            className="btn btn-primary"
                            onClick={onClickAddButton}
                            >
                            Add Meter
                        </button>
                    }
                    </div>
                </div>
                <div className="w-100">
                    {generateMeterList()}
                </div>
                
                <ConfirmModal 
                    isModalVisible={isModalVisible}
                    onRequestClose={onRequestClose}
                    actionState={actionState}
                    onclickYes={onclickYes}
                    fetchMessage={fetchMessage}
                />
            </div>
        </AdminDashboard>
    )
}

export default AdminMetersList;