import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import $ from 'jquery';
import regeneratorRuntime from "regenerator-runtime";

import { selectProfile } from '../redux/slices/profileSlice';
import { selectUsers, removeUser } from '../redux/slices/usersSlice';
import { selectClients, removeClient } from '../redux/slices/clientsSlice';
import { history } from '../App';
import AdminDashboard from './AdminDashboard';
import ConfirmModal from './ConfirmModal';
import { REMOVE_USER } from '../utils/mutations';
import { removeItemFromTable } from '../utils/indexedDB';

const AdminProfile = () => {
    const [ removeSingleUser, { error } ] = useMutation(REMOVE_USER);

    const profile = useSelector(selectProfile)[0];
    const users = useSelector(selectUsers);
    const dispatch = useDispatch();

    const [ actionState, setActionState ] = useState({
        object_id: undefined,
        action_type: undefined
    });
    const [ isModalVisible, setIsModalVisible ] = useState(false);
    const [fetchMessage, setFetchMessage] = useState({
        msg: '',
        msg_class: 'text-danger'
    }); 
    let { path, url } = useRouteMatch(); // to dynamically assign logo source path

    const onClickUpdateButton = (e) => {
        setActionState({
            object_id: $(e.target).prop("id").split('_')[1],
            action_type: 'update'
        })
        setIsModalVisible(true);
    }

    const onClickAddButton = (e) => {
        history.push(`${path}/add`);
    }

    const onClickDeleteButton = (e) => {
        setActionState({
            object_id: $(e.target).prop("id").split('_')[1],
            action_type: 'delete'
        })
        setIsModalVisible(true);
    }

    const onRequestClose = () => {
        setActionState({
            object_id: undefined,
            action_type: undefined
        })
        setIsModalVisible(false);   
    }

    const errorHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-danger'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-danger'
            });
            setIsModalVisible(false);
        }, 3000);
    }
    const successHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-success'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-success'
            });
            setIsModalVisible(false);
        }, 2000);
    }

    const onclickYes = async () => {
        if ( actionState.action_type === 'update' ) {
            history.push(`${path}/update`, { user_id: actionState.object_id });
        } else if ( actionState.action_type === 'delete' ) {
            try{
                const {data:{removeUser:removedUser }} = await removeSingleUser({ variables: { user_id: actionState.object_id } });
                await removeItemFromTable('users', removedUser._id); // remove data from indexedDb
                dispatch(removeUser(removedUser));
                successHandler('User has been deleted successfully!')
            }catch (error){
                console.log(error);
                errorHandler(error.message);
            }
        }   
    }

    const generateUserList = () => (
        users.map((userItem, ind) => (
            <div className="card mb-3 box-shadow meter-card" key={ind}>
                <div className="card-header d-flex justify-content-between">
                    <h4 className="my-0 font-weight-normal d-inline-block">{`${userItem.full_name}`}</h4>
                    <div className="btn-toolbar mb-2 mb-md-0 d-inline-block">
                    {
                        (profile?.admin || (profile._id === userItem._id)) &&
                        <div className="btn-group mr-2 ">
                            <button
                                id={`update_${userItem._id}`} 
                                className="btn btn-sm btn-outline-secondary"
                                onClick={onClickUpdateButton}
                            >Update
                            </button>
                            {
                                (profile._id !== userItem._id) &&
                                <button
                                    id={`delete_${userItem._id}`}
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={onClickDeleteButton}
                                >Delete
                                </button>
                            }
                        </div>
                    }
                        
                    </div>
                </div>
                <div className="card-body">
                    <p className="text-muted"><strong>Email: </strong>{userItem.email}</p>
                    <p className="text-muted"><strong>Role: </strong>{!!userItem.admin ? "Admin" : "User"}</p>
                    <p className="text-muted"><strong>Status: </strong>{userItem.account_status}</p>
                </div>
            </div>
        ))
    );

    return (
        <AdminDashboard>
            <div>
            
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">Admin</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    {
                        profile?.admin &&
                        <button 
                            className="btn btn-primary"
                            onClick={onClickAddButton}
                            >
                            Add User
                        </button>
                    }
                    
                </div>
            </div>
            <div className="w-100">
                {generateUserList()}
                </div>
                <ConfirmModal 
                    isModalVisible={isModalVisible}
                    onRequestClose={onRequestClose}
                    actionState={actionState}
                    onclickYes={onclickYes}
                    fetchMessage={fetchMessage}
                />
            </div>
        </AdminDashboard>
    )
}

export default AdminProfile;