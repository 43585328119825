import React, { useState, useContext } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Auth from '../utils/auth';
import { history } from '../App';
import { clearTables } from '../utils/indexedDB';

const Navbar = () => {
    let { path, url } = useRouteMatch(); // to dynamically assign logo source path
    let location = useLocation();
    const onClickSignoutButton = async () => {
        await clearTables(); // Clear IndexedDB tables
        Auth.logout(); // Reset Auth status
    }

    const getImgLink = () => {
        let [type] = location.pathname.split("/").slice(1);
        let [pathSlug] = location.pathname.split("/").slice(-1);
        let path;
        // console.log("location", location, "type", type, "pathSlug", pathSlug);
        if (type ==="paae" && (pathSlug === "add" || pathSlug === "update")) {
            path = `../../../assets/img/paae_logo_with_title.jpg`;
        }else if (type ==="clients" && (pathSlug === "graph")){
            path = `../../../../assets/img/paae_logo_with_title.jpg`;
        }else{
            path = `../../assets/img/paae_logo_with_title.jpg`;
        }
        return path;
    }

    return (
        <header className="d-flex flex-column flex-md-row justify-content-between align-items-center pt-1 pb-2 px-md-4 bg-white border-bottom box-shadow">
            <div className="my-0 mr-md-auto font-weight-normal">
                <img 
                    src={getImgLink()} 
                    // src={`${"../".repeat(url.split("/").length - 1)}assets/img/paae_logo_with_title.jpg`} 
                    alt="PAAE Logo" 
                    className="img-fluid" 
                    width="180"
                />
                <span className="header-title">Live Monitoring Portal</span>
            </div>
            <nav className="my-2 my-md-0 mr-md-3">
                <button
                    className="btn btn-outline-primary"
                    onClick={onClickSignoutButton}
                > Sign Out
                </button>
            </nav>
        </header>
    )
}

export default Navbar;