import { createSlice, current } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: [],
  reducers: {
    resetProfile: (state, action) =>{
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
    },
    initializeProfile: (state, action) =>{
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
      state.push(action.payload); // payload: {}
    },
    updateProfile: (state, action) => {
      const index = state.findIndex((profile)=>profile._id == action.payload._id);
      state[index] = action.payload; // payload: {}
    },
    // removeProfile: (state, action) => {
    //   const index = state.findIndex((user)=>user._id == action.payload._id);
    //   state.splice(index, 1);
    // }
  }
});

export const { resetProfile, initializeProfile, updateProfile } = profileSlice.actions;

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.cart.value)`
export const selectProfile = state => state.profile;

export default profileSlice.reducer;