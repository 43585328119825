import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import _find from 'lodash.find';
import _isMatch from 'lodash.ismatch';
import regeneratorRuntime, { values } from "regenerator-runtime";
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { selectClients, addClient, updateClient } from '../redux/slices/clientsSlice';
import { capitalizeFirstLetter, runClientAddFormSchema, runClientUpdateFormSchema, runClientUpdatePasswordFormSchema } from '../utils/helpers';
import { history } from '../App';
import { ADD_CLIENT, UPDATE_CLIENT_PROFILE, UPDATE_CLIENT_PASSWORD } from '../utils/mutations';
import AdminDashboard from './AdminDashboard';
import { addItemToTable, updateItemInTable } from '../utils/indexedDB';

const initialState = {
    client_id: '',
    client_company_name: '',
    client_username: '',
    client_status: "ACTIVE",
    client_password: '',
    client_old_password: '',
    client_new_password: ''
};

const ClientForm = (props) => {
    const [ addSingleClient ] = useMutation(ADD_CLIENT);
    const [ updateClientProfile ] = useMutation(UPDATE_CLIENT_PROFILE);
    const [ updateClientPassword ] = useMutation(UPDATE_CLIENT_PASSWORD);

    const dispatch = useDispatch();
    let location = useLocation();
    const clients = useSelector(selectClients);
    
    const [ formAction, setFormAction ] = useState('Add'); 
    const [ clientItem, setClientItem ] = useState(initialState);
    const [ fetchMessage, setFetchMessage ] = useState({
        msg: '',
        msg_class: 'text-danger'
    }); 
    
    useEffect(() => {
        if (!! location.state) {
            if (location.state.client_id) {
                let { _id, company_name, username, status  } = _find(clients, client => client._id === location.state.client_id );
                // setClientItem(prevItem => ({
                //     ...prevItem,
                //     ...tempClientItem
                // }));
                setClientItem(prevItem => ({
                    ...prevItem,
                    client_id: _id,
                    client_company_name: company_name,
                    client_username: username,
                    client_status: status
                }));
                setFormAction('Update');
            }
        } 
    }, [location.state]);

    const errorHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-danger'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-danger'
            }); 
        }, 3000);
    };

    const successHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-success'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-success'
            });
            history.push('/paae/dashboard/clients'); 
        }, 2000);
    };

    const onClickSubmitButton = async ({ client_id, client_company_name, client_status, client_username, client_password, ...rest }, setSubmittingCB) => {
        try {
            if (formAction === 'Add') {
                const {data:{ addClient: addedClient }} = await addSingleClient({ 
                    variables: { 
                        input: {
                            company_name: client_company_name,
                            username: client_username,
                            password: client_password,
                            status: client_status
                        }
                    } 
                });

                await addItemToTable('clients', addedClient); // add item to indexedDb
                dispatch(addClient(addedClient));
                successHandler('Client has been added to the database successfully!');
            } else if (formAction === 'Update') {
                const {data:{ updateClientProfile: updatedClientProfile }} = await updateClientProfile({ 
                    variables: { 
                        input: {
                            client_id,
                            company_name: client_company_name,
                            username: client_username,
                            status: client_status
                        }
                    } 
                });

                await updateItemInTable('clients', updatedClientProfile); // update item in indexedDb
                dispatch(updateClient(updatedClientProfile));
                successHandler('Client profile has been updated successfully!');
            }
            setSubmittingCB(false);
        } catch (error){
            console.log(error);
            errorHandler(error.message);
            setSubmittingCB(false);
        }
    }

    const onClickSubmitUpdatePasswordButton = async ({ client_id, client_old_password, client_new_password, ...rest }, setSubmittingCB) => {
        try{
            const {data } = await updateClientPassword({ 
                variables: { 
                    client_id,
                    old_password: client_old_password,
                    new_password: client_new_password,
                } 
            });

            successHandler('Client password has been updated successfully!');
            setSubmittingCB(false);
        }catch (error){
            errorHandler(error.message);
            setSubmittingCB(false);
        }
    }

    const checkForNewInput = (values) => {
        let flag = false;
        if (!!location.state) {
            if (location.state.client_id) {
                let { _id, company_name, username, status } = _find(clients, client => client._id === location.state.client_id );

                flag = _isMatch(values, { 
                    client_id: _id, 
                    client_company_name: company_name,
                    client_username: username,
                    client_status: status
                });
            };
        }
        return flag;
    }

    return (
        <AdminDashboard>
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                    <h1 className="h2">{`${formAction}`} Client {!!location.state && `(Client ID: ${clientItem.client_id})`}</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                {
                    <Formik
                        enableReinitialize={true}
                        initialValues={clientItem}
                        onSubmit={(values, actions) => {
                            console.log(values);
                            onClickSubmitButton(values, actions.setSubmitting);
                        }}
                        validationSchema={(()=> !!!location.state ? runClientAddFormSchema() : runClientUpdateFormSchema())() }
                    > 
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <div className="card-body">
                                <div className="row pb-3">
                                    <div className="col-md-6 pb-3 pb-md-1">
                                        <strong>Client Username:</strong>
                                        <Field
                                            type="text"
                                            name="client_username"
                                            id="client_username"
                                            className="form-control"
                                            placeholder="abc_corp"
                                        />
                                        <div className="text-danger font-weight-bold">
                                            <ErrorMessage name="client_username" />
                                        </div>
                                        
                                    </div>
                                    {
                                        !!!location.state &&
                                        <div className="col-md-6  pb-3 pb-md-1">
                                            <strong>Client Password:</strong>
                                            <Field
                                                type="password"
                                                name="client_password"
                                                id="client_password"
                                                className="form-control"
                                                placeholder=''
                                            />
                                            <div className="text-danger font-weight-bold">
                                                <ErrorMessage className="text-danger" name="client_password" />
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                                <div className="row pb-3">
                                    <div className="col-md-12"><strong>Client (Company) Name:</strong></div>
                                    <div className="col-md-12">
                                        <Field
                                            type="text"
                                            name="client_company_name"
                                            id="client_company_name"
                                            className="form-control"
                                            placeholder="ABC Corp"
                                        />
                                        <div className="text-danger font-weight-bold">
                                            <ErrorMessage name="client_company_name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-md-6  pb-3 pb-md-1">
                                        <strong>Client Status:</strong>
                                        <Field
                                            name="client_status"
                                            id="client_status"
                                            className="form-control custom-select"
                                            as="select"
                                            onChange={(e) => {
                                                setFieldValue(e.target.name, e.target.value); 
                                            }}
                                        >
                                            <option value={"ACTIVE"}>Active</option>
                                            <option value={"SUSPENDED"}>Suspended</option>
                                        </Field>
                                    </div>
                                </div>
                                <div className="form-row justify-content-center">
                                    <button 
                                        className="btn btn-primary btn-lg"
                                        disabled={checkForNewInput(values) || isSubmitting} 
                                        type="submit"
                                    > 
                                        {formAction} Client 
                                    </button> 
                                </div>
                                <div className={`form-row justify-content-center p-0 ${fetchMessage.msg_class}`} >
                                    <small>{fetchMessage.msg}</small>
                                </div>     
                            </div>
                        </Form>
                    )}
                    </Formik>
                }
                {       
                    (!! location.state) && (
                    <div>    
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 my-3 border-bottom">
                            <h1 className="h3">{`${formAction}`} Password </h1>
                        </div>
                        <Formik
                            enableReinitialize={true}
                            initialValues={clientItem}
                            onSubmit={(values, actions) => {
                                onClickSubmitUpdatePasswordButton(values, actions.setSubmitting);
                            }}
                            validationSchema={runClientUpdatePasswordFormSchema()}
                            > 
                            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <div className="card-body">
                                        <div className="row pb-3">
                                            <div className="col-md-6  pb-3 pb-md-1">
                                                <strong>Old Password:</strong>
                                                <Field
                                                    type="password"
                                                    name="client_old_password"
                                                    id="client_old_password"
                                                    className="form-control"
                                                    placeholder=''
                                                />
                                                <div className="text-danger font-weight-bold">
                                                    <ErrorMessage className="text-danger" name="client_old_password" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pb-3">
                                            <div className="col-md-6  pb-3 pb-md-1">
                                                <strong>New Password:</strong>
                                                <Field
                                                    type="password"
                                                    name="client_new_password"
                                                    id="client_new_password"
                                                    className="form-control"
                                                    placeholder=''
                                                />
                                                <div className="text-danger font-weight-bold">
                                                    <ErrorMessage className="text-danger" name="client_new_password" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row justify-content-center">
                                            <button 
                                                className="btn btn-primary btn-lg"
                                                disabled={isSubmitting} 
                                                type="submit"
                                            > 
                                                {formAction} Password 
                                            </button> 
                                        </div>    
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    )
                }
            </div>
        </AdminDashboard>
    );
}

export default ClientForm;