import { createSlice, current } from '@reduxjs/toolkit';

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: [],
  reducers: {
    resetJobs: (state, action) =>{
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
    },
    initializeJobs: (state, action) => {
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
      action.payload.map(item => state.push(item)); // payload: []
    },
    addJob: (state, action) => {
      state.push(action.payload); // payload: {}
    },
    updateJob: (state, action) => {
      const index = state.findIndex((item)=>item._id == action.payload._id);
      state[index] = action.payload; // payload: {}
    },
    removeJob: (state, action) => {
      const index = state.findIndex((item)=>item._id == action.payload._id);
      state.splice(index, 1); // payload: {}
    }
  }
});

export const { resetJobs, initializeJobs, addJob, updateJob, removeJob } = jobsSlice.actions;

export const selectJobs = state => state.jobs;

export default jobsSlice.reducer;