import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './css/styles.css';
import App from './App';
import store from './redux/store';
import { getAllFromTable } from './utils/indexedDB';
import { initializeProfile } from './redux/slices/profileSlice';
import { initializeUsers } from './redux/slices/usersSlice';
import { initializeClients } from './redux/slices/clientsSlice';
import { initializeMeters } from './redux/slices/metersSlice';
import { initializeJobs } from './redux/slices/jobsSlice';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';


if ( store.getState().profile.length == 0 ) {
    getAllFromTable('profile')
      .then((profile)=> {
        store.dispatch(initializeProfile(profile[0]));
        return getAllFromTable('users');
      })
      .then((users) => {
        store.dispatch(initializeUsers(users));
        return getAllFromTable('clients');
      })
      .then((clients) => {
        store.dispatch(initializeClients(clients));
        return getAllFromTable('meters');
      })
      .then((meters) => {
        store.dispatch(initializeMeters(meters));
        return getAllFromTable('jobs');
      })
      .then((jobs)=> store.dispatch(initializeJobs(jobs)))
      .catch(error => console.log("Error here", error));
    // getAllFromTable('profile').then((profile)=> store.dispatch(initializeProfile(profile[0])));
    // getAllFromTable('users').then((users)=> store.dispatch(initializeUsers(users)));
    // getAllFromTable('clients').then((clients)=> store.dispatch(initializeClients(clients)));
    // getAllFromTable('meters').then((meters)=> store.dispatch(initializeMeters(meters)));
    // getAllFromTable('jobs').then((jobs)=> store.dispatch(initializeJobs(jobs)));
  
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
