import { createSlice, current } from '@reduxjs/toolkit';

export const metersSlice = createSlice({
  name: 'meters',
  initialState: [],
  reducers: {
    resetMeters: (state, action) =>{
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
    },
    initializeMeters: (state, action) => {
      let len = state.length;
      for (let i = 0; i < len; i++ ) state.pop();
      action.payload.map(item => state.push(item)); // payload: []
    },
    addMeter: (state, action) => {
      state.push(action.payload); // payload: {}
    },
    updateMeter: (state, action) => {
      const index = state.findIndex((item)=>item._id == action.payload._id);
      state[index] = action.payload;  // payload: {}
    },
    removeMeter: (state, action) => {
      const index = state.findIndex((item)=>item._id == action.payload._id);
      state.splice(index, 1);  // payload: {}
    }
  }
});

export const { resetMeters, initializeMeters, addMeter, updateMeter, removeMeter } = metersSlice.actions;

export const selectMeters = state => state.meters;

export default metersSlice.reducer;