import * as yup from 'yup';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const signInValidationSchema = userType => {
  if (userType == "client") {
      return yup.object().shape({
          username: yup.string().min(4).required(),
          password: yup.string().min(2).required() // yup.string().min(6).required() USE THIS!!!! 
      });
  } else {
      return yup.object().shape({
          username: yup.string().email().required(),
          password: yup.string().required().min(2) //yup.string().required().min(6) USE THIS!!!! 
      });
  }
};

// Improve these definitions later
export const runMeterFormSchema = () => (yup.object().shape({
  meter_serial_number: yup.string('Serial number must be a string')
                          .required('Serial number is required!')
                          .min(4, 'Serial number must be at least 4 characters long'),
  meter_password: yup.string('Password must be a string')
                      .required('Password is required!')
                      .min(2, 'Password must be at least 2 characters long'),
  meter_data_path: yup.string('Data path must be a string')
                      .required('Data path is required!'),
  meter_name: yup.string('Meter name must be a string')
                  .required('Meter name is required!'),
  meter_description: yup.string(),
  meter_status: yup.string().required(),
  meter_country_of_operation: yup.string().required()
}));

export const runClientAddFormSchema = () => (yup.object().shape({
  client_company_name: yup.string('Client name must be a string')
              .required('Client name is required!')
              .min(4, 'Client name must be at least 4 characters long'),
  client_password: yup.string('Password must be a string')
              .required('Password is required!')
              .min(2, 'Password must be at least 2 characters long'),
  client_username: yup.string('Client username must be a string')
              .required('Client username is required!')
              .min(4, 'Client username must be at least 4 characters long'),
  client_status: yup.string()
}));

export const runClientUpdateFormSchema = () => (yup.object().shape({
  client_company_name: yup.string('Client name must be a string')
              .required('Client name is required!')
              .min(4, 'Client name must be at least 4 characters long'),
  client_username: yup.string('Client username must be a string')
              .required('Client username is required!')
              .min(4, 'Client username must be at least 4 characters long'),
  client_status: yup.string()
}));

export const runClientUpdatePasswordFormSchema = () => (yup.object().shape({
  client_old_password: yup.string('Password must be a string')
              .required('Password is required!')
              .min(2, 'Password must be at least 2 characters long'),
  client_new_password: yup.string('Password must be a string')
              .required('Password is required!')
              .min(2, 'Password must be at least 2 characters long'),

}));

export const runUserAddFormSchema = () => (yup.object().shape({
  first_name: yup.string('First name must be a string')
              .required('First name is required!'),
  last_name: yup.string('Last name must be a string')
              .required('Last name is required!'),
  email: yup.string('Email must be a string')
              .email('Must be a valid email!')
              .required('Email is required!'),
  password: yup.string('Password must be a string')
              .required('Password is required!')
              .min(2, 'Password must be at least 2 characters long'),
  admin: yup.boolean(),
  account_status: yup.string()
}));

export const runUserUpdateFormSchema = () => (yup.object().shape({
  first_name: yup.string('First name must be a string')
              .required('First name is required!'),
  last_name: yup.string('Last name must be a string')
              .required('Last name is required!'),
  email: yup.string('Email must be a string')
              .email('Must be a valid email!')
              .required('Email is required!'),
  admin: yup.boolean(),
  account_status: yup.string()
}));

export const runUserUpdatePasswordFormSchema = () => (yup.object().shape({
  old_password: yup.string('Password must be a string')
              .required('Password is required!')
              .min(2, 'Password must be at least 2 characters long'),
  new_password: yup.string('Password must be a string')
              .required('Password is required!')
              .min(2, 'Password must be at least 2 characters long'),

}));

export const runJobFormSchema = () => (yup.object().shape({
  client: yup.string('Client ID must be a string')
                  .required('Client ID is required!'),
  number: yup.string('Job number must be a string')
                  .required('Job number is required!')
                  .min(4, 'Job number must be at least 4 characters long'),
  title: yup.string('Job title must be a string')
                  .required('Job title is required!'),
  description: yup.string(),
  status: yup.string()
              .required('Job Status is required!'),
  meters: yup.array()
      .of( yup.object().shape({
          meter: yup.string('Client ID must be a string')
                    .required('Client ID is required!'),
          meter_location_title: yup.string('Location title must be a string')
                              .required('Location title is required!'),
          lng: yup.number().typeError('Longitude must be numeric (between -180.0 and 180.0)')
                  .min(-180.0,  'Longitude must be between -180.0 and 180.0')
                  .max(180.0, 'Longitude must be between -180.0 and 180.0')
                  .required('Longitude is required!'),
          lat: yup.number().typeError('Latitude must be numeric (between -90.0 and 90.0)')
                  .min(-90.0, 'Latitude must be between -90.0 and 90.0')
                  .max(90.0,  'Latitude must be between -90.0 and 90.0')
                  .required('Latitude is required!')
      })
      ).required('Must have at least one meter'),
}));





export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const appendZero = input => {
	const inputStr = input.toString();
  	return inputStr.length < 2 ? `0${inputStr}` : inputStr;
};

export const formatDate = dateObj => {
  	return `${dateObj.getFullYear()}-${appendZero(dateObj.getMonth() + 1)}-${appendZero(dateObj.getDate())}`;
}

export const addDays = (oldDate, nDays) => {
    return new Date(oldDate.setDate(oldDate.getDate() + nDays));
};




export const RatingIconSVG = {
  box: "0 0 133 135",
  coords: `M49.32,124a24.69,24.69,0,0,1-1.45-16c.78-3.43,1.93-6.79,3-10.14a127.59,127.59,0,0,0,4.67-17c.36-2,.59-4,.8-6,.69-6.79,3.58-12.38,9.41-16,6.84-4.3,14-4.75,21.18-.75,3.75,2.09,7.37,4.42,11.08,6.59,2,1.19,4.1,2.35,6.23,3.38a59.49,59.49,0,0,0,5.89,2.46,51.28,51.28,0,0,1,16.19,9c6.83,5.78,8,13.18,5.54,20.89a20.91,20.91,0,0,1-4.64,7.89c-1.91,2.06-4.39,2.86-7,3.45-5.67,1.29-11.36,2.51-17,3.82-2,.46-4,1.06-5.93,1.69a22.8,22.8,0,0,0-7.43,4.56c-3.52,2.91-7,5.9-10.52,8.79a37.85,37.85,0,0,1-4.13,2.85,13.6,13.6,0,0,1-8.32,2c-6.92-.52-12.51-3.44-16.44-9.27C50,125.44,49.71,124.72,49.32,124Z

  M92.26,15.79c3.1,7.05,4.16,14,2.27,21.21a17.22,17.22,0,0,1-6,9.74c-4.2,3.23-8.8,3.54-13.59,1.49a20.92,20.92,0,0,1-9.12-7.86,31.21,31.21,0,0,1-5.17-23A19.27,19.27,0,0,1,65.77,6.6,13,13,0,0,1,81.09,4.05a22.64,22.64,0,0,1,9.4,8.66C91.18,13.81,91.78,15,92.26,15.79Z
       
  M56.23,39.34a25.74,25.74,0,0,1,2.58,13.53c-.43,4.19-2,7.82-5.42,10.45a13.2,13.2,0,0,1-8.73,2.61,18.07,18.07,0,0,1-7.91-2c-9.94-5.24-15.7-13.42-17-24.6a17.36,17.36,0,0,1,1-8,13,13,0,0,1,12.91-8.82,22.19,22.19,0,0,1,13.1,5A32.27,32.27,0,0,1,56.23,39.34Z
  
  M129.69,30.72c3,7.4,3.19,15.75-1.61,23.81a14.71,14.71,0,0,1-9.92,7.37c-5,1-9.24-.67-12.86-4a23.56,23.56,0,0,1-7.09-14A25.67,25.67,0,0,1,101,26.79a16.42,16.42,0,0,1,6.54-6.65,13.19,13.19,0,0,1,13.33.15C124.73,22.43,127.31,25.74,129.69,30.72Z
  
  M43.75,76.77a22.42,22.42,0,0,1,2.5,10.49c-.24,8-5.89,13.7-13.91,14a22.44,22.44,0,0,1-14.27-4.73A25.93,25.93,0,0,1,7.37,80.12a16.74,16.74,0,0,1,.45-8.37C9.69,66,14.67,62.4,21.06,62.21a22.38,22.38,0,0,1,13.07,3.94A27,27,0,0,1,43.75,76.77Z`
}


export function objectToArray(coordsObject) {
  return Object.values(coordsObject);
}

export function middleValueOfArray(arr) {
  if (arr!==0){
    const middleIndex = Math.floor(arr.length / 2);
    return arr[middleIndex];
  }
  else {
    return []; // Tracking not started
  }
}

// // indexedDB setup
// export function idbPromise(storeName, method, object) {
//     return new Promise((resolve, reject) => {
//       const request = window.indexedDB.open('woof', 1);
//       let db, tx, store;
//       // if version has changed (or if this is the first time using the database), run this method and create the three object stores 
//       request.onupgradeneeded = function(e) {
//         const db = request.result;
//         // create object store for each type of data and set "primary" key index to be the `_id` of the data
//         db.createObjectStore('user', { keyPath: '_id' });
//         db.createObjectStore('orders', { keyPath: '_id' });
//       };
  
//       request.onerror = function(e) {
//         console.log('There was an error');
//       };
  
//       request.onsuccess = function(e) {
//           // save a reference of the database to the `db` variable
//         db = request.result;
//         // open a transaction do whatever we pass into `storeName` (must match one of the object store names)
//         tx = db.transaction(storeName, 'readwrite');
//         // save a reference to that object store
//         store = tx.objectStore(storeName);
  
//         db.onerror = function(e) {
//           console.log('error', e);
//         };
  
//         switch (method) {
//           case 'put':
//             store.put(object);
//             resolve(object);
//             break;
//           case 'get':
//             const all = store.getAll();
//             all.onsuccess = function() {
//               resolve(all.result);
//             };
//             break;
//           case 'delete':
//             store.delete(object._id);
//             break;
//           default:
//             console.log('No valid method');
//             break;
//         }
        
//         // when the transaction is complete, close the connection
//         tx.oncomplete = function() {
//           db.close();
//         };
//       };
//     });
//   }


