import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import _find from 'lodash.find';
import _isMatch from 'lodash.ismatch';
import regeneratorRuntime, { values } from "regenerator-runtime";
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { selectProfile, updateProfile } from '../redux/slices/profileSlice';
import { selectUsers, addUser, updateUser } from '../redux/slices/usersSlice';
import { capitalizeFirstLetter, runUserAddFormSchema, runUserUpdateFormSchema, runUserUpdatePasswordFormSchema } from '../utils/helpers';
import { history } from '../App';
import AdminDashboard from './AdminDashboard';
import { ADD_USER, UPDATE_USER_PROFILE, UPDATE_USER_PASSWORD } from '../utils/mutations';
import { addItemToTable, updateItemInTable } from '../utils/indexedDB';


const initialState = {
    _id: '',
    email: '',
    first_name: '',
    last_name: '',
    account_status: "ACTIVE",
    admin: true,
    password: '',
    old_password: '',
    new_password: ''
};

const ProfileForm = (props) => {
    const [ addSingleUser ] = useMutation(ADD_USER);
    const [ updateUserProfile ] = useMutation(UPDATE_USER_PROFILE);
    const [ updateUserPassword ] = useMutation(UPDATE_USER_PASSWORD);

    const dispatch = useDispatch();
    const profile = useSelector(selectProfile)[0];
    const users = useSelector(selectUsers);

    const [ formAction, setFormAction ] = useState('Add'); 
    const [ adminItem, setAdminItem ] = useState(initialState);
    const [ fetchMessage, setFetchMessage ] = useState({
        msg: '',
        msg_class: 'text-danger'
    }); 
    let location = useLocation();

    useEffect(() => {
        if (!! location.state) {
            if (location.state.user_id) {
                let tempUserItem = _find(users, user => user?._id === location.state.user_id );
                setAdminItem(prevItem => ({
                    ...prevItem,
                    ...tempUserItem
                }));
                setFormAction('Update');
            }
        } 
    }, [location.state, users]);

    const errorHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-danger'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-danger'
            }); 
        }, 3000);
    }
    const successHandler = (message) => {
        setFetchMessage({
            msg: message,
            msg_class: 'text-success'
        });
        setTimeout(()=>{
            setFetchMessage({
                msg: '',
                msg_class: 'text-success'
            });
            history.push('/paae/dashboard/profile'); 
        }, 2000);
    }
    const onClickSubmitButton = async ({ _id, email, password, first_name, last_name, account_status, admin, ...rest }, setSubmittingCB) => {
        try {
            if (formAction === 'Add') {
                const {data:{ addUser: addedUser }} = await addSingleUser({ 
                    variables: { 
                        input: {
                            first_name,
                            last_name,
                            email,
                            password,
                            admin,
                            account_status
                        }
                    } 
                });

                await addItemToTable('users', addedUser); // add item to indexedDb
                dispatch(addUser(addedUser));
                successHandler('User has been added to the database successfully!');
            } else if (formAction === 'Update') {
                const {data:{ updateUserProfile: updatedUserProfile }} = await updateUserProfile({ 
                    variables: { 
                        input: {
                            user_id: _id,
                            first_name,
                            last_name,
                            email,
                            admin,
                            account_status
                        }
                    } 
                });

                await updateItemInTable('users', updatedUserProfile); // add item to indexedDb
                dispatch(updateUser(updatedUserProfile));
                if (profile?._id === updatedUserProfile._id) { // if the updated profile is the same as the user profile
                    await updateItemInTable('profile', updatedUserProfile); // add item to indexedDb
                    dispatch(updateProfile(updatedUserProfile));
                }
                successHandler('User profile has been updated successfully!');
            }
            setSubmittingCB(false);
        } catch (error){
            console.log(error);
            errorHandler(error.message);
            setSubmittingCB(false);
        }
    }

    const onClickSubmitUpdatePasswordButton = async ({_id, old_password, new_password, ...rest }, setSubmittingCB) => {
        try{
            const {data } = await updateUserPassword({ 
                variables: { 
                    user_id: _id,
                    old_password,
                    new_password
                } 
            });

            successHandler('User password has been updated successfully!');
            setSubmittingCB(false);
        }catch (error){
            errorHandler(error.message);
            setSubmittingCB(false);
        }
    }

    const checkForNewInput = (values) => {
        let flag = false;
        if (!!location.state) {
            if (location.state.user_id) {
                let tempUserItem = _find(users, user => user?._id === location.state.user_id );
                flag = _isMatch(values, tempUserItem);
            };
        }
        return flag;
    }
    return (
        <AdminDashboard>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">{`${formAction}`} User {!!location.state && `(User ID: ${adminItem._id})`}</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                </div>
            </div>
            {
                <Formik
                    enableReinitialize={true}
                    initialValues={adminItem}
                    onSubmit={(values, actions) => {
                        // console.log(values);
                        onClickSubmitButton(values, actions.setSubmitting);
                    }}
                    validationSchema={(()=> !!!location.state ? runUserAddFormSchema() : runUserUpdateFormSchema())()}
                > 
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="card-body">
                            <div className="row pb-3">
                                <div className="col-md-6 pb-3 pb-md-1">
                                    <strong>First Name:</strong>
                                    <Field
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        className="form-control"
                                        placeholder="Jeff"
                                    />
                                    <div className="text-danger font-weight-bold">
                                        <ErrorMessage name="first_name" />
                                    </div>
                                </div>
                                <div className="col-md-6 pb-3 pb-md-1">
                                    <strong>Last Name:</strong>
                                    <Field
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        className="form-control"
                                        placeholder="Moe"
                                    />
                                    <div className="text-danger font-weight-bold">
                                        <ErrorMessage name="last_name" />
                                    </div>
                                </div>  
                            </div>

                            <div className="row pb-3">
                                <div className="col-md-6 pb-3 pb-md-1">
                                    <strong>User Email:</strong>
                                    <Field
                                        type="text"
                                        name="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="me@example.com"
                                    />
                                    <div className="text-danger font-weight-bold">
                                        <ErrorMessage name="email" />
                                    </div>
                                </div>
                                {
                                    !!!location.state &&
                                    <div className="col-md-6 pb-3 pb-md-1">
                                        <strong>User Password:</strong>
                                        <Field
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="form-control"
                                            placeholder=""
                                        />
                                        <div className="text-danger font-weight-bold">
                                            <ErrorMessage name="password" />
                                        </div>
                                    </div>  
                                }
                            </div>

                            <div className="row pb-3">
                                <div className="col-md-6  pb-3 pb-md-1">
                                    <strong>Role:</strong>
                                    <Field
                                        name="admin"
                                        id="admin"
                                        className="form-control custom-select"
                                        as="select"
                                        onChange={(e) => {
                                            setFieldValue(e.target.name, e.target.value=="true" ? true : false); 
                                        }}
                                    >
                                        <option value={true}>Super Admin</option>
                                        <option value={false}>General Admin</option>
                                    </Field>
                                </div>
                                <div className="col-md-6  pb-3 pb-md-1">
                                    <strong>Account Status:</strong>
                                    <Field
                                        name="account_status"
                                        id="account_status"
                                        className="form-control custom-select"
                                        as="select"
                                        onChange={(e) => {
                                            setFieldValue(e.target.name, e.target.value); 
                                        }}
                                    >
                                        <option value={"ACTIVE"}>Active</option>
                                        <option value={"SUSPENDED"}>Suspended</option>
                                    </Field>
                                </div>      
                            </div>
                            <div className="form-row justify-content-center">
                                <button 
                                    className="btn btn-primary btn-lg"
                                    disabled={checkForNewInput(values) || isSubmitting} 
                                    type="submit"
                                > 
                                    {formAction} User 
                                </button> 
                            </div>
                            <div className={`form-row justify-content-center p-0 ${fetchMessage.msg_class}`} >
                                <small>{fetchMessage.msg}</small>
                            </div>     
                        </div>
                    </Form>
                )}
                </Formik>
            }
            {       
                (!! location.state) && (
                <div>    
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 my-3 border-bottom">
                        <h1 className="h3">{`${formAction}`} Password </h1>
                    </div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={adminItem}
                        onSubmit={(values, actions) => {
                            // console.log(values);
                            // console.log("adminItem", adminItem);
                            onClickSubmitUpdatePasswordButton(values, actions.setSubmitting);
                        }}
                        validationSchema={runUserUpdatePasswordFormSchema()}
                    > 
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <div className="card-body">
                                <div className="row pb-3">
                                    <div className="col-md-6  pb-3 pb-md-1">
                                        <strong>Old Password:</strong>
                                        <Field
                                            type="text"
                                            name="old_password"
                                            id="old_password"
                                            className="form-control"
                                            placeholder=''
                                        />
                                        <div className="text-danger font-weight-bold">
                                            <ErrorMessage className="text-danger" name="old_password" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col-md-6  pb-3 pb-md-1">
                                        <strong>New Password:</strong>
                                        <Field
                                            type="text"
                                            name="new_password"
                                            id="new_password"
                                            className="form-control"
                                            placeholder=''
                                        />
                                        <div className="text-danger font-weight-bold">
                                            <ErrorMessage className="text-danger" name="new_password" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row justify-content-center">
                                    <button 
                                        className="btn btn-primary btn-lg"
                                        disabled={isSubmitting} 
                                        type="submit"
                                    > 
                                        {formAction} Password 
                                    </button> 
                                </div>    
                            </div>
                        </Form>
                    )}
                    </Formik>
                </div>
                )
            }
        </AdminDashboard>
    );
}

export default ProfileForm;