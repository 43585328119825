import React, { useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoBox } from '@react-google-maps/api';
import dotenv from 'dotenv';

dotenv.config();

const containerStyle = {
  width: '100%',
  height: '600px'
};

function GoogleComponent({ mapCenter, zoom, markers }) {
    const center = {
        lat: mapCenter.lat,
        lng: mapCenter.lng
    }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API
  });

  const [map, setMap] = React.useState(null)

//   useEffect(()=>{
//       if(map){
//           console.log("with map obj");
//         map.setCenter({
//             lat: mapCenter.lat,
//             lng: mapCenter.lng
//         })
//       }
//   }, [map]);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    
    setMap(map);
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, [])

  return (isLoaded) ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {
            markers && 
            markers.map((marker, ind) => (
              <Marker
                key={ind} 
                position={marker.location}
                title={marker.title || ""} 
                // onClick={props.handleMarkerOnClick} 
              />
                ))
            }
      </GoogleMap>
  ) : <div></div>
}

export default GoogleComponent;





// import React from "react"
// import { compose, withProps } from "recompose"
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

// const GoogleMapComponent = compose(
//   withProps({
//     // googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB3uKupYmpuiBhQb8MjRvfaR8wEKjG9SGU&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `600px` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   withScriptjs,
//   withGoogleMap
// )((props) => (
//   <GoogleMap
//     defaultZoom={12}
//     defaultCenter={{ lat: 51.04997688673528, lng: -114.0616466226032 }}
//     zoom={props.zoom}
//     center={props.center}
//   >
    // {
    //   props.markers && 
    //   props.markers.map((marker, ind) => (
    //     <Marker
    //       key={ind} 
    //       position={marker.location}
    //       title={marker.title || ""} 
    //       // onClick={props.handleMarkerOnClick} 
    //     />
    //       ))
    //   }
//   </GoogleMap>
//   )
// )

// export default GoogleMapComponent;